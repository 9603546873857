import React, {useEffect, useState, useContext} from "react";
// import HeatMap from "./HeatMap";
// import assetDetail from "./assetDetails";
import { Grid, Tooltip, } from "@mui/material";
// import Table from '@mui/material/Table';

// import TableCell from '@mui/material/TableCell';

// // import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import HeatMap2 from "./HeatMap2";
// import HeatMap from "./HeatMap";
import { withStyles } from "@mui/styles";
import {CircularProgress } from '@mui/material';
import moment from "moment/moment";
import UserContext from 'Context/UserContext';
import {  useHistory } from "react-router-dom";
import { useMediaQuery, createTheme , IconButton} from '@material-ui/core';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import API from "API/apiConfig";




const APIURL = process.env.REACT_APP_APIURL;

const styles={
  legendText:{
    paddingTop: '1rem',
     fontSize: '13px', 
      whiteSpace: "pre-line", 
      // paddingLeft:'1rem'
  },
  tdStylesViofor2:{
    // width:'10rem',
    wordWrap:'break-word',
    height:'4rem',
     padding:"0.5rem" ,
        fontSize:'14px',
         border:"0.5px solid #D3D3D3", 
       backgroundColor:'#FCD0E0',
      //  width:'15%',
       cursor:"pointer",
     "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
      zIndex:1
    },
    },
    tdStylesViofor4:{
      wordWrap:'break-word',
      // width:'10rem',
      height:'4rem',
       padding:"0.5rem" ,

      fontSize:'14px',
       border:"0.5px solid #D3D3D3", 
     backgroundColor:'#FF87B3',
    //  width:'15%',
     cursor:"pointer",
   "&:hover": {
    boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
    zIndex:1
  },
  },
  tdStylesViofor8:{
    // width:'10rem',
    wordWrap:'break-word',
    height:'4rem',
     padding:"0.5rem" ,
    fontSize:'14px',
     border:"0.5px solid #D3D3D3", 
   backgroundColor:'#F3367B',
  //  width:'15%',
   cursor:"pointer",
 "&:hover": {
  boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
  zIndex:1
},
},
tdStylesViofor16:{
  // width:'10rem',
  wordWrap:'break-word',
  height:'4rem',
   padding:"0.5rem" ,
  fontSize:'14px',
   border:"0.5px solid #D3D3D3", 
 backgroundColor:'#ED0E0E',
//  width:'15%',
 cursor:"pointer",
"&:hover": {
  boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
    zIndex:1
},
},
    tdStylesWar:{
      wordWrap:'break-word',
      // width:'10rem',
      height:'4rem',
       padding:"0.5rem" ,
      fontSize:'14px',
      border:"0.5px solid #D3D3D3", 
    backgroundColor:'rgba(220,172,25, 0.5)',
    // width:'15%',
    cursor:"pointer",
  "&:hover": {
    boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.7)",
    zIndex:1
 },
    },
    tdStylesNone:{
      // minwidth:'10rem',
      wordWrap:'break-word',
      height:'4rem',
       padding:"0.5rem" ,
      fontSize:'14px',
      border:"0.5px solid #D3D3D3", 
    backgroundColor:'rgba(169,208,208)',
    // width:'15%',
    cursor:"pointer",
  "&:hover": {
    boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
    zIndex:1
 },
    },
    aggrid:{
      height: '4rem', 
      padding: '0.5rem',
       fontSize: '14px', 
       fontWeight: '600', 
       color:'rgba(0,0,0,0.8)',
       border: '0.6px solid  rgba(224, 224, 224, 1)'
    },
    '@media screen and (max-width: 1200px)':{
      tdStylesNone:{
        // minwidth:'10rem',
        wordWrap:'break-word',
        height:'3rem',
         padding:"0.5rem" ,
        fontSize:'12px',
        border:"0.5px solid #D3D3D3", 
      backgroundColor:'rgba(169,208,208)',
      // width:'15%',
      cursor:"pointer",
    "&:hover": {
       boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
      zIndex:1
   },
    },
    aggrid:{
      height: '3rem', 
      padding: '0.5rem',
       fontSize: '12px', 
       fontWeight: '600', 
       color:'rgba(0,0,0,0.8)',
       border: '0.6px solid  rgba(224, 224, 224, 1)',
       
    },
    tdStylesViofor2:{
      // width:'10rem',
      wordWrap:'break-word',
      height:'3rem',
       padding:"0.5rem" ,
          fontSize:'12px',
           border:"0.5px solid #D3D3D3", 
         backgroundColor:'#FCD0E0',
        //  width:'15%',
         cursor:"pointer",
       "&:hover": {
        boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
        zIndex:1
      },
      },
      tdStylesViofor4:{
        wordWrap:'break-word',
        // width:'10rem',
        height:'3rem',
         padding:"0.5rem" ,
  
        fontSize:'12px',
         border:"0.5px solid #D3D3D3", 
       backgroundColor:'#FFD0E0',
      //  width:'15%',
       cursor:"pointer",
     "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
      zIndex:1
    },
    },
    tdStylesViofor8:{
      // width:'10rem',
      wordWrap:'break-word',
      height:'3rem',
       padding:"0.5rem" ,
      fontSize:'12px',
       border:"0.5px solid #D3D3D3", 
     backgroundColor:'#F3367B',
    //  width:'15%',
     cursor:"pointer",
   "&:hover": {
    boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
    zIndex:1
  },
  },
  tdStylesViofor16:{
    // width:'10rem',
    wordWrap:'break-word',
    backgroundColor:'#ED0EOE',
    height:'3rem',
     padding:"0.5rem" ,
    fontSize:'12px',
     border:"0.5px solid #D3D3D3", 
 
  //  width:'15%',
   cursor:"pointer",
  "&:hover": {
    boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
      zIndex:1
  },
  },
      tdStylesWar:{
        wordWrap:'break-word',
        // width:'10rem',
        height:'3rem',
         padding:"0.5rem" ,
        fontSize:'12px',
        border:"0.5px solid #D3D3D3", 
      backgroundColor:'rgba(220,172,25, 0.5)',
      // width:'15%',
      cursor:"pointer",
    "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.5)",
      zIndex:1
   },
      },

  },

    heatMapgradient1:{
      display:'flex', 
      paddingLeft:'2rem'
    },
    heatMapgradient2:{
      display:'flex', 
      paddingLeft:'1rem'
    },
    emptyChamber: {
    padding:'0.5rem',
    fontSize:'14px',
     border:'0.6px solid  rgba(224, 224, 224, 1)',
     width:'8rem'
    },
    

}

const lineGradient=[
    {
    duration:' - > 8hrs in violation',
    color:'#ED0E0E',
    },
    {
    duration:' - 4-8hrs in violation',
    color:'#F3367B',
     },
    {
     duration:' - 2-4hrs in violation',
     color:'#FF87B3',
     },
    {
      duration:' - 0-2hrs in violation',
      color:'#FCD0E0',
    },
    {
        duration:` - In Warning`,
        color:'rgba(220,172,25, 0.7)'
    },
    {
        duration:' - No violation',
        color:'rgba(169,208,208)'
    },

]

const Summary=(props)=>{
    const {classes, date, searchId, menuItem, chipFilters, warehouseType, chamberType, handlchipInitialstate, refreshData}= props;
    const [isLoading, setIsLoading]=useState(true);
    const [heatMapData, setheatMapData]=useState();
    const [heatMapDataCopy, setheatMapDataCopy]=useState();
    const [heatMapDataCopy1, setheatMapDataCopy1]=useState();
    const { updateSingleChamber, } = useContext(UserContext);
    const history = useHistory();
    const[maxlength, setMaxLength]= useState(0)
  

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    });
    
     const lgBreakPoint = useMediaQuery(theme.breakpoints.up('lg'));
    //  const xsBreakpoint= useMediaQuery(theme.breakpoints.up('sm'));

    

    const getAssetGroupDetail=()=>new Promise((resolve, reject)=>{
      
      const startInUnix=moment(date[0]).unix()
      const endInUnix=moment(date[1]).unix()
      setIsLoading(true)
      API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${true}`)
      .then((response) => {
        if (response.data.status === 'success') {
       
          resolve(response.data.assetGroups);
          
          this.setState({
            isLoading: false,
          });
        } else {
          setIsLoading(false)
          reject(new Error('Error loading warehouses'));
        }
      })
      .catch((err) => {
       
        reject(new Error(err));
        setIsLoading(false)
        
      });
     })
  
  const getAssetStatus=(response)=>{
  
  
    const assetGroupWithAssetStatus=response.map((ag)=>{ 
      if(ag.assets && ag.assets.length){   
        ag.assets.map((ast)=>{
          // const newAsset = { ...ast };
      
          if(ast && Object.prototype.hasOwnProperty.call(ast,"eventSummary")){
            ast.is_Violated=false; ast.is_Warned=false;
            if (ast.eventSummary && ast.eventSummary.violation && ast.eventSummary.violation) {
              if (ast.eventSummary.violation.temperature && ast.eventSummary.violation.temperature.events > 0) {
                ast.is_Violated = true;
                // 
              }
              if (ast.eventSummary.violation.humidity && ast.eventSummary.violation.humidity.events > 0) {
                ast.is_Violated = true;
                // 
              }
              // if (ast.eventSummary.violation.battery && ast.eventSummary.violation.battery.events > 0) {
              //   ast.is_Violated = true;
              //   // 
              // }
            }
            if (ast.eventSummary && ast.eventSummary.warning && ast.eventSummary.warning){
              if((
                ast.eventSummary.warning.temperature && 
               ast.eventSummary.warning.temperature.events > 0) && !ast.is_Violated
               ){
                 ast.is_Warned=true;
               }
               if((
                  ast.eventSummary.warning.humidity && 
                 ast.eventSummary.warning.humidity.events > 0) && !ast.is_Violated ){
                   ast.is_Warned=true;
                 }
                //  if((
                //     ast.eventSummary.warning.battery && 
                //    ast.eventSummary.warning.battery.events > 0) &&  !ast.is_Violated 
                //    ){
                //      ast.is_Warned=true;
                //    }
            }
  
            
          }else{
            ast.is_Violated=false;
            ast.is_Warned=false;
          }
          return ast
        })
        return ag;
      }
      else{
        return ag
      }
      
    })
   
    return assetGroupWithAssetStatus
  }

    const getInitialData=()=>{
    
      getAssetGroupDetail()
      .then((response)=>{
      
       const assetsWithStatus= getAssetStatus(response)
    
      let maxLength = 1;
    
      assetsWithStatus.forEach(ag=>{
      
       maxLength =  Math.max(maxLength, ag.assets ? ag.assets.length : 0)
      })
      setMaxLength(maxLength +1);
    
      setheatMapData(assetsWithStatus)
      setheatMapDataCopy(assetsWithStatus)
      setIsLoading(false);
      })
      .catch(()=>{
        setIsLoading(false);
      })
    }


    const handleSearchInput=()=>{
        if (searchId && searchId.length > 1){
          const arraytoMapOn= menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val) ? heatMapDataCopy1 : heatMapDataCopy
            let assetGroupSuggestions = [];
            const filterAssetsGp = arraytoMapOn
            .map((assestgp) => {
              if (
                assestgp.id
                  .toLowerCase()
                  .includes(searchId.toLowerCase())
              ) {
                // this solves,
                // warehouse doesn't show all chambers after we filter by chamber - as we are setting assets to particular searched asset below
                const arraytoMapOnAsset= menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val) ? heatMapDataCopy1 : heatMapData
                const alertAsset = arraytoMapOnAsset.find((a) =>
                  a.id
                    .toLowerCase()
                    .includes(assestgp.id.toLowerCase())
                );
                assestgp.assets = alertAsset.assets;
                assetGroupSuggestions = assestgp;
                return assetGroupSuggestions;
              }
               
            
              const filterassets =
                assestgp.assets &&
                assestgp.assets.filter((a) =>
                  a.id.toLowerCase().includes(searchId.toLowerCase())
                );
                
              if (filterassets && filterassets.length) {
                //take the reference of assetgp and add filteredasset to that assetgp object and return that value.
                const assestgpCopy = { ...assestgp };
    
                assestgpCopy.assets = filterassets;
                return assestgpCopy;
              }
             
            
              
              return null;
            })
            .filter((m) => m);

            setheatMapDataCopy(filterAssetsGp)
        }
        //if we go back , no search is there 
        if(searchId.length === 0 ){
         
          if(menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val)){
            setheatMapDataCopy(heatMapDataCopy1);
          }else{
            setheatMapDataCopy(heatMapData);
          }
         
        }
    }


    const handlechamberCardClick=(selectedAssetUid)=>{
      // console.log('Card Click',selectedAssetUid)
      
     
      // this.onDashboardStateChange();
     
    
      const startTime = moment().startOf('day').unix();
      const endTime = moment().unix();
  
      history.push(
        `/dashboard?assetUid=${selectedAssetUid}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`
      );
  
      updateSingleChamber(true);
      handlchipInitialstate({
        temperature: false,
        humidity: false,  
      })
   }

   useEffect(()=>{
    history.push(
      `/dashboard`
    );
   },[])

   const handleAlertstype=(allAssetsGp,status)=>{
 
    if(status === "Violations"){
     
      const assetWithVio=allAssetsGp && allAssetsGp.length && allAssetsGp.map((ag, )=>{
        if(ag.assets && ag.assets.length ){
          const filteredAssets = ag.assets.filter((ast)=>ast.is_Violated === true)
          if(filteredAssets && filteredAssets.length){
            const copy={...ag}
            copy.assets=filteredAssets
            return copy;
          }
          return null;
         
        }
      }).filter((m) => m);
      return assetWithVio;
    }
  
  
  if(status === "Warnings"){
   
    const assetWithVio=allAssetsGp && allAssetsGp.length && allAssetsGp.map((ag, )=>{
      if(ag.assets && ag.assets.length ){
        const filteredAssets = ag.assets.filter((ast)=>( ast.is_Warned === true))
        if(filteredAssets && filteredAssets.length){
          const copy={...ag}
          copy.assets=filteredAssets
          return copy;
        }
        return null;
       
      }
    }).filter((m) => m);
    return assetWithVio;
  }
  if(status === "All Alerts"){
    return allAssetsGp
  }
  
  
  }

  const isViolatedOrWarned=(evtSummary, type)=>{
    if(menuItem === "Violations"){
      return (evtSummary['violation'][type] !== null  && evtSummary['violation'][type].events  &&  evtSummary['violation'][type].events > 0 )
    }
    if(menuItem === "Warnings"){
     return (evtSummary['warning'][type] !== null && evtSummary['warning'][type].events &&  evtSummary['warning'][type].events > 0 )
    }
    if(menuItem === "All Alerts"){
      return (evtSummary['violation'][type] !== null  && evtSummary['violation'][type].events  &&  evtSummary['violation'][type].events > 0 )
     || (evtSummary['warning'][type] !== null && evtSummary['warning'][type].events &&  evtSummary['warning'][type].events > 0 )
    }
    // return (evtSummary['violation'][type] !== null  && evtSummary['violation'][type].events  &&  evtSummary['violation'][type].events > 0 )
    //  || (evtSummary['warning'][type] !== null && evtSummary['warning'][type].events &&  evtSummary['warning'][type].events > 0 )
    }

  const handleChipFilters=(allAssetsGroup, filterArray)=>{
    if(filterArray && filterArray.length){
    
        const assetWithVio = allAssetsGroup && allAssetsGroup.length && allAssetsGroup.map((ag,) => {
          let filteredAssets = []
          if (ag.assets && ag.assets.length) {
            filteredAssets =  ag.assets.filter((ast) => {
              //to check if eventSummary object exist or not
              if (Object.prototype.hasOwnProperty.call(ast, "eventSummary")) {
                //check eventSummary value should not be null
               if(!Object.is(ast.eventSummary,null)){
                let isviolated = false;
                filterArray.some(type => {
                  if (isViolatedOrWarned(ast.eventSummary, type)) isviolated = true
                  return isViolatedOrWarned(ast.eventSummary, type)
                })
                // console.log('isviolated', isviolated)
                return isviolated
               }
               
              }
    
              // console.log('ag.assets', ag.assets)
            })
          }
          return {...ag, assets:filteredAssets}
        }).filter(ag=> ag && ag.assets && ag.assets.length)
        // console.log('assetWithVio', assetWithVio)
        return assetWithVio;
      }
    }
  

  const handleFilters=()=>{
    const filtersArr = Object.keys(chipFilters);
    const trueFilters = filtersArr.filter((filter) => chipFilters[filter] === true);
    let allAssetsDetails=heatMapData
    // console.log('AllDetails', allAssetsDetails)
    if(trueFilters && trueFilters.length){
      allAssetsDetails = handleChipFilters(allAssetsDetails, trueFilters);
    }
    if(menuItem && menuItem.length){
      
      allAssetsDetails=handleAlertstype(allAssetsDetails, menuItem)
     
    }
  
    setheatMapDataCopy(allAssetsDetails)
    setheatMapDataCopy1(allAssetsDetails)
  // console.log('trueFilters', trueFilters, menuItem, allAssetsDetails, )
  
  }

 

    useEffect(()=>{ 
        getInitialData()
    },[date,refreshData])

    useEffect(()=>{
        handleSearchInput()  
    },[searchId])

    useEffect(()=>{
      handleFilters()
    },[menuItem, chipFilters])
     
    const getAssetColor=(eventSummary)=>{
      const temVioDuration = eventSummary && eventSummary.violation && eventSummary.violation.temperature 
        && eventSummary.violation.temperature.eventsDurationSecs > 0 ? Number(eventSummary.violation.temperature.eventsDurationSecs) / 3600  : 0 ;
      const humVioDuration = eventSummary && eventSummary.violation && eventSummary.violation.humidity 
        && eventSummary.violation.humidity.eventsDurationSecs > 0 ? Number(eventSummary.violation.humidity.eventsDurationSecs) / 3600  : 0 ;
      const duration = Math.max( temVioDuration, humVioDuration)
     
      if(duration > 0 && duration <= 2){
        return classes.tdStylesViofor2
      }
      if(duration > 2 && duration <= 4){
        return classes.tdStylesViofor4
      } 
      if(duration > 4 && duration <= 8){
       
        return classes.tdStylesViofor8
      }
      if(duration > 8){
        return classes.tdStylesViofor16
      }
     


    }

    

  return (

    <>
      {
        isLoading ?
          (<div 
            style={{
              position: "fixed",
              display: "block",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: "2",
            }}
         >
            <CircularProgress
              style={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          </div>)
          :
          (

            <>
              {/* <div style={{marginTop:'1rem', width:'100%'}}> */}
              {/* <HeatMap /> */}

             
              {
                !lgBreakPoint ?
             
                  lineGradient.map((points)=>(
                    <Grid key={points.duration}>
                       <Grid style={{display:'flex', padding:'5px'}}>
                      <Grid style={{backgroundColor:points.color, width:'15px', height:'15px',borderRadius:'22px', }} />
                        <Grid style={{fontSize:'10px'}}> {points.duration}</Grid>
                        </Grid>
                    </Grid>
                  ))
                 
                : ''
              }
             
              <Grid container columns={20} >

                <Grid item xl={20} lg={20} md={20} xs={20} style={{ fontSize: '16px', paddingBottom: '0.5rem',color:'#008081', }} >
                  {chamberType ? chamberType : "Chamber(s)"}
                  <IconButton style={{
                    backgroundColor:'transparent',
                    cursor:'default',
                   color:'#008081b3 '
                  }}><EastIcon  /></IconButton>
                  
                  </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={2} style={{ marginTop:  '40vh' }} >
                  <div style={{ transform: "rotate(-90deg)", fontSize: '16px',display:'flex',  color:'#008081'}}>
                 <WestIcon sx={{mr:'0.3rem'}} />  {warehouseType ? warehouseType.length > 11 ? `${warehouseType.slice(0, 10)}...` : warehouseType : " Warehouse(s)"}
               </div>
                </Grid>
                <Grid item xl={16} lg={15} md={18} sm={17} xs={17}  >

                  <Grid
                    style={{
                      overflowX: 'auto',
                      overflowY: 'auto',
                      height: '75vh',
                      // width:'100%',
                      backgroundColor: '#fff',
                      border: "2px solid #D3D3D3",

                    }}>


           
                    {
                      heatMapDataCopy && heatMapDataCopy.length ?
                        heatMapDataCopy.map((ag) => (

                          <>
                            <Grid 
                             style={{ display: 'grid', width:'100%', 
                           
                             gridTemplateColumns:`repeat(${maxlength}, 8rem)`}} 
                             key={ag.uid}
                             >
                              <Grid 
                              className={classes.aggrid}
                              key={ag.uid}
                              // style={{ height: '4rem', padding: '0.5rem', fontSize: '14px', fontWeight: '400', border: '0.6px solid  rgba(224, 224, 224, 1)' }}
                               >
                                {ag.id && ag.id.length > 20 ?
                                <Tooltip title={ag.id}>
                                <span >
                               {ag.id.slice(0, 20) + "..." }
                             </span>
                             </Tooltip>
                               : ag.id}
                              </Grid>
                              {/* <Grid style={{display:'flex'}}> */}
                              {
                                ag.assets && ag.assets.length ?
                                  ag.assets.map((ass) => (
                                   
                                    <Grid key={ass.uid}
                                      className={ass.is_Violated ? getAssetColor(ass.eventSummary ? ass.eventSummary : {}) : ass.is_Warned ? classes.tdStylesWar : classes.tdStylesNone}
                                  

                                      onClick={() => handlechamberCardClick(ass.uid)}
                                    >
                                      {/* { handleAssetColor(ass)} */}
                                   
                                      {ass.id && ass.id.length > 25 ? 
                                      <Tooltip title={ass.id}>
                                         <span key={ass.uid}>
                                        {ass.id.slice(0, 25) + "..." }
                                      </span>
                                      </Tooltip>
                                     
                                   
                                      : ass.id}
                                    </Grid>
                                  ))

                                  :
                                  <Grid  className={classes.emptyChamber}>
                                    {chamberType ? "No " + chamberType : "No Chamber"}
                                    
                                    </Grid>
                              }
                            {/* </Grid> */}
                            </Grid>
                          </>
                        ))
                        :
                        <Grid  sx={{mt:'15%'}} >
                          <h3>
                            <img src="../../img/emptybox.png" alt="empty" style={{margin:'auto', display:'flex'}}></img>
                            No { warehouseType? warehouseType + " " :  "Warehouse "}found
                            </h3>
                        </Grid>
                       
                    }




                    
                  </Grid>
                </Grid>
                        {
                          lgBreakPoint ?
                          <Grid item xl={3} lg={4} md={1} sm={1} xs={0} style={{ margin: 'auto' }} >
                          {
                            lineGradient.map((line) => (
                              <Grid key={line.duration} className={lgBreakPoint ? classes.heatMapgradient1 : classes.heatMapgradient2}  >
                                <Grid 
                                 style={{ height: '8vh', width: '5px', backgroundColor: line.color , borderBottom:'2px solid #fff'  }}></Grid>
                                <Grid className={classes.legendText}>{line.duration}</Grid>
                              </Grid>
                            ))
                          }
        
        
                        </Grid>
                          :
                         ''
                         

                        }

                       
              
              </Grid>

              {/* </div> */}
            </>
          )

      }

    </>
  )
}
export default withStyles(styles)(Summary);

