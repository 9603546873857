/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CtoF } from '../utils/helpers';


const styles = {
  root: {
    padding: '2px',
    paddingBottom: '20px !important',
    borderRadius:'22px'
  },
  
};

class TemperatureHumidityGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.getOptions(this.props),
      

    };
  }
 

  shouldComponentUpdate(nextProps) {
     
    const nextPings = nextProps.devicesWithPings;
    const nextHumidityData=nextProps.hideHumidityData;
    const currentPings = this.props.devicesWithPings;
    const currentHumidityData=this.props.hideHumidityData;
    // const nextDev=nextProps.chamberDEVICES;
    // const currentDev=this.props.chamberDEVICES;
  
    let shouldRender = false;
    if (nextPings.length !== currentPings.length || nextHumidityData !== currentHumidityData  ) {
      shouldRender = true;
    } else {
      nextPings.forEach((device, index) => {
        if (device.data && currentPings[index].data && device.data.length !== currentPings[index].data.length) {
          shouldRender = true;
        }
      });
    }
    // console.log('should render ', shouldRender, this.props, this.props.devicesWithPings);
    if (shouldRender) {
      this.updateOptions(nextProps);
    
    }
    return true;
  }

  generateSeriesData = (devicesWithPings, hide_Humiditydata) => {
    const { companySettings } = this.props;
    // console.log('Getting device Pings',devicesWithPings, settings,companySettings  );
    
    const seriesData = [];
    // filter the devices that have device pings
    const filteredData = devicesWithPings.filter((device) => { 
      if (device.data && device.data.length > 0) {
        return device;
      }
    });
    // format the device pings so that the pings can be used for graph
    filteredData.forEach((device) => { 
      // filter the pings that have invalid timestamp
      const filteredPings = device.data.filter((ping) => ( ping && ping.timeStamp && moment(ping.timeStamp) !== 'Invalid date')); 
      const deviceTemperaturePings = [];
      const deviceHumidityPings = [];
      filteredPings.forEach((ping) => {
        // console.log('ping time ', ping);
        // each ping has frequency, temperature and location
        let temperatureFrequency = 60; // seconds
        if (ping.interval && ping.interval.collection && Number(ping.interval.collection)) {
          temperatureFrequency = Number(ping.interval.collection) * 60;
        }
        // console.log('ping ', ping.timeStamp, ping.temperature);
        // console.log('ping data ', ping);
        const t1 = ping.temperature || [];
        const { humidity } = ping;
        // let temperatureAndTimePoints = [];
        for (let i = 0; i < t1.length; i++) {
          let t1WithTimeStamp = [];
          let humidityWithTimeStamp = [];
          let dataTimeStamp = null;
          if (t1 && t1.length) {
            dataTimeStamp = ping.timeStamp - (temperatureFrequency * (t1.length - i - 1));
            if (companySettings && companySettings.settings && companySettings.settings.temperature && companySettings.settings.temperature === 'F') {
              t1WithTimeStamp = [moment(dataTimeStamp * 1000).utc().format(), CtoF(t1[i])];
            } else {
              t1WithTimeStamp = [moment(dataTimeStamp * 1000).utc().format(), t1[i]];
            }
          }
          if (humidity && humidity[i]) {
            humidityWithTimeStamp = [moment(dataTimeStamp * 1000).utc().format(), humidity[i]];
          }
         
          deviceTemperaturePings.push(t1WithTimeStamp);
          deviceHumidityPings.push(hide_Humiditydata ? [] : humidityWithTimeStamp );
        }
        // return ([moment(ping.timestamp).utc().format(), ping.t1]) //return each device ping in the format ['2019-18-11T13:12:11Z', 21.3]
      });
   
      // console.log('Props', this.props,this.props.settings &&  this.props.settings.temperature && this.props.settings.temperature.minViolation);   
    //   const { minTemperature } = this.props.settings && this.props.settings.temperature && this.props.settings.temperature.minViolation;
    //   const { maxTemperature } =this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.maxViolation;

    seriesData.push(
      {
        name: device.deviceId,
        // name:'min',
        textStyle: {
          fontSize: 16
        },
        type: 'line',
        data: deviceTemperaturePings,
        
        smooth: true,
        showSymbol: false,
        markLine: {
          data: [
            {
            // type: 'min',
            label: {
              position: 'insideEndTop',
              // distance:[-50,100],
              formatter: `Min. Threshold : ${ this.props.settings && this.props.settings.temperature && this.props.settings.temperature.minViolation}°C`,
            },
            name: 'Min',
            yAxis: this.props.settings && this.props.settings.temperature && this.props.settings.temperature.minViolation !== undefined ? this.props.settings.temperature.minViolation :'',
         
             // put the value of min of temp
            lineStyle: {
              normal: {
                type: 'dashed',
                color: '#7c2d12ff',
              },
            },
            tooltip:{
              trigger:'item',
              formatter: () => {
               
                return  `Min. Threshold : ${ this.props.settings && this.props.settings.temperature && this.props.settings.temperature.minViolation}°C`;
              },
            }
          },
          {
            // type: 'max',
            name: 'Max',
            label: {
              position: 'insideEndTop',
              formatter: `Max. Threshold : ${this.props.settings && this.props.settings.temperature && this.props.settings.temperature.maxViolation}°C`,
            },
            yAxis: this.props.settings && this.props.settings.temperature && this.props.settings.temperature.maxViolation !== undefined ? this.props.settings.temperature.maxViolation : '', // put the value of max of temp
            lineStyle: {
              normal: {
                type: 'dashed',
                color: '#dc2626',
              },
            },
            tooltip:{
              trigger:'item',
              formatter: () => {
               
                return  `Max. Threshold : ${this.props.settings && this.props.settings.temperature && this.props.settings.temperature.maxViolation}°C`;
              },
            }
          },
          ],

        },
        
      },
       {
        name: device.deviceId,
        type: 'line',
        textStyle: {
          fontSize: 16
        },
        data: deviceHumidityPings,
       
        smooth: true,
        showSymbol: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
        
        markLine: {
          data: [{
          // type: 'min',
            label: {
              position: 'insideEndTop',
              formatter: `Min.Threshold : ${ this.props.settings && this.props.settings.humidity && this.props.settings.humidity.minViolation}%`,
            },
            yAxis: this.props.settings && this.props.settings.humidity &&   this.props.settings.humidity.minViolation !== undefined ? this.props.settings.humidity.minViolation : '',
            name: 'Min',
            lineStyle: {
              normal: {
                type: 'dashed',
                color: '#7c2d12ff',
              },
            },
            tooltip:{
              trigger:'item',
              formatter: () => {
               
                return  `Min.Threshold : ${ this.props.settings && this.props.settings.humidity && this.props.settings.humidity.minViolation}%`;
              },
            }
          },
          {
          // type: 'max',
            name: 'Max',
            label: {
              position: 'insideEndTop',
              formatter: `Max.Threshold : ${  this.props.settings && this.props.settings.humidity && this.props.settings.humidity.maxViolation}%`,
            },
            yAxis: this.props.settings && this.props.settings.humidity && this.props.settings.humidity.maxViolation !== undefined? this.props.settings.humidity.maxViolation : '', // put the value of max of temp
            lineStyle: {
              normal: {
                type: 'dashed',
                color: '#dc2626',
              },
            },
            tooltip:{
              trigger:'item',
              formatter: () => {
               
                return  `Max.Threshold : ${  this.props.settings && this.props.settings.humidity && this.props.settings.humidity.maxViolation}%`;
              },
            }
          },
          ],
        },
        
      },

    );

    });
    return seriesData;
  }

  getOptions = (props) => {
    const {
      devicesWithPings,
      // settings,
      companySettings,
      hideHumidityData,
    } = props;
   
   
    const seriesData = this.generateSeriesData(devicesWithPings, hideHumidityData);
 
    const maxTempValue = (value) => {
    
      // if(this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.maxViolation ){
        
      // const maxvalue=this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.maxViolation;
      
      // if(maxvalue){
      //   if (value.max < maxvalue ) {
          
      //     return Math.round(this.props.settings.temperature.maxViolation + 5);
      //   }
      //   return Math.round(value.max + 5);
      //  }
        
       if(this.props.settings && this.props.settings.temperature){
        if(this.props.settings.temperature.maxViolation !== undefined){
          const maxvalue=this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.maxViolation;
      
   
        if (value.max < maxvalue ) {
          
          return Math.round(this.props.settings.temperature.maxViolation + 5);
        }
        return Math.round(value.max + 5);
       }
        }
       

      // }
     
    };
    const minTempValue = (value) => {
     
      //  if(this.props.settings && this.props.settings.temperature ){
        //  const maxvalue=this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.minViolation !== undefined;
        //  console.log('Mim value',maxvalue)
        //  if(maxvalue){
        //   if (value.min > maxvalue ) {
          
        //     return Math.round(this.props.settings.temperature.minViolation - 5);
        //   }
        //   return Math.round(value.min - 5);
        //  }
        
      //  }

      if(this.props.settings && this.props.settings.temperature){
        
        if( this.props.settings.temperature.minViolation !== undefined ){
          const maxvalue=this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.minViolation;
         
          if (value.min > maxvalue ) {
          
            return Math.round(this.props.settings.temperature.minViolation - 5);
          }
          
          return Math.round(value.min - 5);
        }
      }
      
     };
 
    const maxHumidity = (value) => {
      // if(this.props.settings && this.props.settings.humidity &&  this.props.settings.humidity.maxViolation ){
      //   const maxvalue= this.props.settings && this.props.settings.humidity && this.props.settings.humidity.maxViolation;
      //  if(maxvalue){
      //   if (value.max < maxvalue ) {
         
      //     return Math.round(maxvalue + 5);
      //   }
      //   return Math.round(value.max + 5);
      //  }
       
      // }
      if(this.props.settings && this.props.settings.humidity){
        if(this.props.settings.humidity.maxViolation !== undefined){
          const maxvalue=  this.props.settings.humidity.maxViolation;
          if (value.max < maxvalue ) {
         
                return Math.round(maxvalue + 5);
              }
              return Math.round(value.max + 5);
             }
        }
      
     
    };

    const minHumidity = (value) => {
     
      // if(this.props.settings && this.props.settings.temperature &&  this.props.settings.temperature.maxViolation ){
      //   const maxvalue=this.props.settings && this.props.settings.humidity &&  this.props.settings.humidity.minViolation;
      //  if(maxvalue){
      //   if (value.min > maxvalue ) {
          
      //     return Math.round(maxvalue - 5);
      //   }
      //   return Math.round(value.min - 5);
      //  }
       
       if(this.props.settings && this.props.settings.humidity){
      
        if( this.props.settings.humidity.minViolation !== undefined){
         
          const maxvalue= this.props.settings.humidity.minViolation;
          
        
           if (value.min > maxvalue ) {
          
             return Math.round(maxvalue - 5);
           }
           return Math.round(value.min - 5);
         
        }
       }

      // }
     
    };
    const options = {
      
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const { data } = params[0];
          return `${data[1]} \n @${moment(data[0]).format('HH:mm DD-MMM-YY')}`;
        },
        axisPointer: {
          animation: false,
        },
      },
      grid: [
        {
          left: '10%',
          right: '10%',
          bottom: '53%',
        },
        {
          left: '10%',
          right: '10%',
          top: '58%',
        },
      ],
      legend: {
        // data: devices
        // data: ['sales']
      },
      xAxis: [{
        type: 'time',
        boundaryGap: false,
        splitNumber:0,
        axisTick: {
          // show: false,
          alignWithLabel: true,
      
        },
        // axisLabel: {
        //   show: false,
          
        // },
        axisLabel: { 
          formatter: (value) => {
            const date = moment(value);
            return `${date.format('HH:mm')}\n${date.format('Do MMM')}`;
          },
        padding: [0, 0, 5, 0],
        interval:0,
        // rotate:60,
        hideOverlap:true
        },
        //   axisLine: {
        //   show: false,
        // },
        /* name: 'Time',
        nameLocation: 'center',
        nameGap: 47,
        nameTextStyle: {
          fontWeight: 'bold',
        }, */
      }, {
        gridIndex: 1,
        type: 'time',
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
         
        },
        axisLabel: {
          formatter: (value) => {
            const date = moment(value);
            return `${date.format('HH:mm')}\n${date.format('Do MMM')}`;
          },
          padding: [0, 0, 5, 0],
        interval:0,
        // rotate:60,
        hideOverlap:true
        },
        name: 'Time',
        nameLocation: 'center',
        nameGap: 47,
        nameTextStyle: {
          fontWeight: 'bold',
        },
      }],
      yAxis: [
        {
          type: 'value',
          name: (companySettings && companySettings.settings.temperature && companySettings.settings.temperature === 'F') ? 'Temperature (°F)' : 'Temperature (°C)',
          nameLocation: 'center',
          nameRotate: 90,
          nameTextStyle: {
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            fontSize: 12,
          },
          // min: this.props.settings && this.props.settings.temperature && this.props.settings.temperature.minViolation,
          // min:  this.props.settings.temperature.minViolation,
          min:minTempValue,
          max: maxTempValue,
          nameGap: 40,
          scale: true,
          minInterval: 1,
          // min: (settings.temperature),
          // max: maxTemp,
          // interval: 2,
          splitNumber: 5,
          // axisLabel: {
          //   formatter: '{value} °C',
          // },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              const { data } = params[0];
              if (companySettings && companySettings.settings.temperature && companySettings.settings.temperature === 'F') {
                return `Time: ${moment(data[0]).format('DD-MMM-YY: HH:mm')}, Temperature: ${CtoF(data[1])} \xB0F`;
              }
              return `Time: ${moment(data[0]).format('DD-MMM-YY: HH:mm')}, Temperature: ${data[1]} \xB0C`;
            },
            axisPointer: {
              animation: false,
            },
          },
        },
        {
          type: 'value',
          name: hideHumidityData ? ''  : 'Humidity (%)',
          gridIndex: 1,
          nameLocation: 'center' ,
          nameRotate:  90 ,
          nameTextStyle: {
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            
          },
          // min: this.props.settings && this.props.settings.humidity && this.props.settings.humidity.minViolation,
          min:minHumidity,
          max: maxHumidity,

          nameGap: 40,
          scale: true,
          minInterval: 5,
          // min: minHumidity,
          // max: maxHumidity,
          // interval: 20,
          // axisLabel: {
          //   formatter: '{value} %',
          // },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              const { data } = params[0];
              return `Time: ${moment(data[0]).format('DD-MMM-YY: HH:mm')}, Humidity: ${data[1]} %`;
            },
            axisPointer: {
              animation: false,
            },
          },
        },


      ],
      // visualMap:{
      //   top: 50,
      //   right: 10,
      //   pieces:[{
      //     gt: 0,
      //       lte: 50,
      //       color: 'red'
      //   }]
      // },
      series: seriesData,
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          height: 15,
          bottom: 15,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '100%',
          showDataShadow: true,
          // fillerColor: '#cd5c5c',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
          xAxisIndex: [0, 1],
        },

        /* {
          type: 'slider',
          start: 0,
          end: 100,
          // height: 20,
          // bottom: 35,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '100%',
          showDataShadow: true,
          // fillerColor: '#cd5c5c',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
          xAxisIndex: [0, 1],
        }, */
      ],
    };
    return options;
  };

  updateOptions = (props) => {
    const options = this.getOptions(props);
    this.setState({
      options,
    }, () => {
      const echartsInstance = this.echartsReact.getEchartsInstance();
      echartsInstance.setOption(options, true);
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    const { options, } = this.state;

    return (
      <div>
        <Card style={{ margin: '20px 20px', borderRadius:'22px', }}>

          <CardContent
            classes={{
              root: classes.root,
            }}
          >
            {/* <Grid container >
              <Grid item xs={4} className={classes.subheading}>
              Temperature Humidity Graph
              </Grid>
              <Grid item xs={4}>
                <Divider style={{marginTop:'1rem', color:'#000'}} />
              </Grid>
            </Grid> */}
            {/* <div style={{ background: '#36B37E', color: 'white', padding: '10px' }}>Temperature Humidity Graph</div> */}
            <ReactEcharts
              ref={(e) => { this.echartsReact = e; }}
              style={{ height: '80vh',  }}
              option={options}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

TemperatureHumidityGraph.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  devicesWithPings: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TemperatureHumidityGraph);
