import React from "react";
import { withStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import Alert from '@mui/material/Alert';

const styles={

}



const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      
    },
  },
};


const ToleranceSetting=(props)=>{
 
 
  const { 
    toleranceValueinSeconds,
    handledefaultTolerance,
    toleranceCheckboxValue,
    handleswitchOptionChange
  }= props;

  

const selectOptions=[{
  id:900,
  time:'15 minutes',
  seconds:900,
},
{
  id:1800,
  time:'30 minutes',
  seconds:1800,
},
{
  id:3600,
  time:'1 hour',
  seconds:3600,
},
{
  id:7200,
  time:'2 hours',
  seconds:7200,
},
{
  id:14400,
  time:'4 hours',
  seconds:14400,
},
{
  id:21600,
  time:'6 hours',
  seconds:21600,
},
{
  id:28800,
  time:'8 hours',
  seconds:28800,
},
{
  id:36000,
  time:'10 hours',
  seconds:36000,
},

]
  
 
const handleswitchoption=(event)=>{
handleswitchOptionChange(event.target.value)
}

const handleChange=(event)=>{

  handledefaultTolerance(event.target.value)
}
  
  return(
    <div style={{padding:'0.5rem'}}>
   
    <FormControl style={{width:'100%'}}>
   
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={toleranceCheckboxValue}
      name="radio-buttons-group"
    >
      <FormControlLabel value="instant" control={<Radio  
     
      
      onChange={handleswitchoption}
       />
      } 
       
       label="Instant(Default)" />

       <div ><Alert severity="info">Notification would be sent as soon as any violation is recorded.</Alert></div>

      <FormControlLabel value="custom" control={<Radio 
      
      
      onChange={handleswitchoption}
      />} label="Custom" />
      
    
    </RadioGroup>
  </FormControl>
  <Grid container spacing={2} style={{paddingTop:'0.7rem'}} >


<Grid item  >
<span style={{margin:'auto'}}>Time Tolerance:</span></Grid>

  <FormControl variant="standard" sx={{ m: 1, minWidth: 120, textAlign:'left', }}>
        
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={toleranceValueinSeconds}
          onChange={handleChange}
          disabled={toleranceCheckboxValue=== 'instant'? true: false}
          MenuProps={MenuProps}
        >
          {selectOptions.map((val)=>(
            <MenuItem key={val.id} value={val.id}>{val.time}</MenuItem>
          ))}
         
        </Select>
      </FormControl>

    
   
     
    
     </Grid>
     <Alert severity="info">Minimum time duration before notifying about persisting violation.</Alert>
  </div>
  )



}
export default withStyles(styles)(ToleranceSetting);