import { TextField, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import InputAdornment from '@mui/material/InputAdornment';
// import {alpha} from '@mui/material';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useMediaQuery, createTheme } from '@material-ui/core';
import API from 'API/apiConfig';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import cogoToast from 'cogo-toast';

import { List as VirtualizedList, AutoSizer } from 'react-virtualized';

// import PropTypes from 'prop-types';
import UpdateCalendar from 'components/UpdateCalendar';
import DeviceRow from './DeviceRow';
import Devicecontainer from './Devicecontainer';


const APIURL = process.env.REACT_APP_APIURL;

const styles = {


}

// const useStyles = makeStyles(() => ({
//   root: {
//     width: '100%',
//     height: '90vh',
//     maxWidth: 300,
//     marginTop: '2vh',
//     // backgroundColor: theme.palette.background.paper,
//   },


// }));

function DeviceView(props) {
  const { classes } = props;
  const [devicelist, setDevicelist] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [dates, setDates] = useState({ start: moment().subtract(7, 'days').startOf('day'), end: moment() });
  const [selectedQuickOption, setSelectedQuickOption] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [assetGroupname, setAssetGroupName]=useState([]);
  // const filterdevice = devicelist && devicelist.filter((data) => (data.id === searchValue));
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1540,
      },
    },
  });
  const hideCalendar = useMediaQuery(theme.breakpoints.up('md'));
  const hideChip = useMediaQuery(theme.breakpoints.up('xl'));
  const ListLenght = useMediaQuery(theme.breakpoints.up('md'));
  const lgBreak = useMediaQuery(theme.breakpoints.up('lg'));

  function getDevices() {
    API
      .get(`${APIURL}/devices`)
      .then(async(response) => {
        if (
          response.data
          && response.data.status
          && response.data.status === 'success'
        ) {
          const devices = response.data.data
          devices.map(async(a)=>{
            a.temperatureSettings = a.assetTemperatureSettings || {}
            a.humiditySettings = a.assetHumiditySettings || {}
            // if(a.assetUid){
            //   const resp = await API
            //     .get(`${APIURL}/assets?uid=${a.assetUid}`)
            //     .catch((err)=>{
            //         console.log('Err', err)
            //         });
            //         if (resp?.data && resp?.data.data[0] && resp?.data?.data[0]){
            //           if( resp?.data?.data[0]?.temperatureSettings){
            //             const { temperatureSettings } = resp.data.data[0]; 
                     
            //             a.temperatureSettings = temperatureSettings ? temperatureSettings : {};
            //             // return a;
            //           }
            //           if(resp?.data?.data[0]?.humiditySettings){
            //             const { humiditySettings } = resp.data.data[0];
                      
            //             a.humiditySettings = humiditySettings ? humiditySettings : {};
            //             // return a;
            //           }
            //           return a;
                      
            //         }else{
            //           console.log('Err**' )
            //         }
            // }
          })
        
          setDevicelist(devices);
          setIsLoading(false)
        } else {
          cogoToast.error(
            response.data.message ? response.data.message : 'Internal Error',
            { position: 'top-right' },
          );
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('error getting devices', error.message || error);
        setIsLoading(false)
      })
  }



  const filterdevice = devicelist && devicelist.filter((d) => (searchValue
    ? (d.id || ' ').toLowerCase().trim().includes(searchValue.toLowerCase()) ||
    (d.assetId || ' ').toLowerCase().trim().includes(searchValue.toLowerCase()) ||
    (d.assetGroupId || ' ').toLowerCase().trim().includes(searchValue.toLowerCase())
    : true
  ));

  // const filterdevice = devicelist.filter((d) => (isDeviceFiltered(d, filters) && (searchValue ? (d.id || '').toLowerCase().includes(searchValue.toLowerCase()) : true)));
  // console.log('device lists', 'devicelist', devicelist, 'filterdevice', filterdevice);

  const handleDeviceCardClick = (device) => {
    // console.log('Deleted device is', device, 'Selectedd device', selectedDevices);
    if (selectedDevices.filter((d) => d.id === device.id).length) {
      const newSelectedDevices = selectedDevices.filter((d) => d.id !== device.id); // to delete selected device
      setSelectedDevices(newSelectedDevices);
    } else {
      const newSelectedDevices = [...selectedDevices, device];
     
      setSelectedDevices(newSelectedDevices);
    }
  };


  const handleDeviceRowClick = (device) => {
    // console.log('called', selectedDevices, devicelist, device);
    if (selectedDevices.filter((d) => d.id === device.id).length) {
      const newSelectedDevices = selectedDevices.filter((d) => (d.id !== device.id));
      setSelectedDevices(newSelectedDevices);

    } else {

      // const newSelectedDevices = [...((e && e.ctrlKey) ? selectedDevices : []), device]; // if we select more device using ctrl key
      const newSelectedDevices = [device]
      setSelectedDevices(newSelectedDevices);
    }

    // setSelectedDevices(selectedDevices.length);
  };


  useEffect(() => {
    getDevices();
  }, []);


  function renderRow({
    index, style,
  }) {
    // console.log('I am devicelist', devicelist, index, filterdevice);
    const device = filterdevice[index];
    const selected = !!selectedDevices.find((d) => (d.id === device.id));
    // console.log('Devices',device,selected,filterdevice )
    return (
      <div key={index} style={{
        ...style,
        backgroundColor: '#f2f2f2', width: '96%',
      }}>
        <DeviceRow device={device} onClick={handleDeviceRowClick} selected={selected} />

      </div>
    );
  }

  const handleRangeChange = (start, end) => {
    // console.log('St', start.startOf('day'),end.startOf('day'))
   
    setDates({start, end });
    setSelectedQuickOption(0);
  };

  const handleQuickOptionsChange = (value) => {
    if ([30, 15, 7].includes(value)) {
      setDates({ start: moment().subtract(value, 'days').startOf('day'), end: moment() });
      setSelectedQuickOption(value);
    } else {
      setSelectedQuickOption(0);
    }
  };

  const rowHeightRenderer = ({ index }) => {
    const device = filterdevice[index];
    // console.log('Row',device )
    if (!device.paired) return 90;
    return lgBreak ? 150 : 180;
  };

  return (
    <>
      {
        isLoading ?
          <>
            <div className={classes.overlay}>
              <CircularProgress
                style={{ position: 'absolute', top: '50%', left: '50%' }}
              />
            </div>
          </> :
          <Grid style={{ height: '100%', marginBottom: '2rem' }}>
            <Grid style={{ marginTop: '3%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4" component="h4" display="inline" color="textPrimary" gutterBottom style={{ fontSize: '36px', fontWeight: 700 }}>Device View</Typography>

            </Grid>

            {/* <Paper style={{ margin: '1%', textAlign: 'left', padding: '3px' }}> */}
            <Grid
              container
              xs={12}


              style={{
                display: 'flex', justifyContent: 'space-between', minHeight: '3em', alignItems: 'center', marginBottom: '1.5%',
              }}
            >
              <Grid item
                xl={3} lg={3} md={3} sm={12} xs={12}
              >

                <TextField
                  value={searchValue}
                  variant="standard"
                  placeholder="Search "
                  onChange={(e) => { setSearchValue(e.target.value); }}
                  //  onChange={getvalue}
                  style={{ width: '100%' }}
                  id="input-with-icon-textfield"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      padding: '1rem',
                      // border: '1px solid #f2f2f2',
                      border: 'none',
                      backgroundColor: '#fff',
                      height: '2.25rem',
                      borderRadius: '22px',
                      fontSize: '14px',
                      fontFamily: 'Montserrat',
                      // width: '24rem',
                      width: '100%'
                    },
                  }}
                />

              </Grid>
              {hideCalendar ?
                <>
                  <Grid
                    style={{ paddingLeft: '6rem' }}
                    item
                    xl={5} lg={8} md={9} sm={12} xs={12}
                  >
                    {selectedDevices && selectedDevices.length
                      ? (
                        <UpdateCalendar

                          startDate={dates.start}
                          endDate={dates.end}
                          handleRangeChange={handleRangeChange}
                          handleQuickOptionsChange={handleQuickOptionsChange}
                          selectedQuickOption={selectedQuickOption}
                        />
                      )
                      : ' '}

                  </Grid>
                </>
                :
                " "
              }


              {hideChip ?
                <>
                  <Grid
                    style={{ alignItems: 'right', marginBottom: '1.5%', textAlign: 'right' }}
                    item
                    lg={4} md={4} sm={12} xs={12}
                  >

                    {selectedDevices && selectedDevices.length ? 'Selected devices: ' : ''}

                    {selectedDevices && selectedDevices.map((d) => (
                      <Chip
                        key={d.IMEI}
                        style={{ border: ' 1px solid #008081', }}
                        // color="secondary"
                        // icon={<FaceIcon />}
                        label={d.id}
                        // onClick={handleClick}
                        onDelete={() => handleDeviceCardClick(d)}
                        variant="outlined"
                      />
                    ))}
                  </Grid>
                </> :
                " "

              }

            </Grid>
            {/* </Paper> */}


            <div
              style={{
                //  height: '90vh', 
                paddingTop: hideCalendar ? '0rem' : '1.5rem'
              }}
            >

              <Grid container style={{ display: "flex" }}>



                {/* <Grid item md={3} className={classes.root}>
       <FixedSizeList height={1200} width={300} itemSize={1} itemCount={devicelist && devicelist.length}>
         {renderRow}
       </FixedSizeList> */}
                <Grid item xl={3} lg={4} md={5} sm={12} xs={12} style={{
                  height: ListLenght ? '90vh' : '50vh',
                }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <VirtualizedList
                        rowRenderer={renderRow}
                        // style={{backgroundColor:'pink'}}
                        // columnCount={selectedDevices.length ? 1 : 4}
                        // columnWidth={width / 4}
                        height={height}

                        rowCount={filterdevice.length}
                        rowHeight={rowHeightRenderer}
                        width={width}
                      />
                    )}
                  </AutoSizer>
                </Grid>
                {
                  hideCalendar ?
                    " " :
                    <Grid
                      style={{ paddingTop: '3rem' }}
                      item
                      md={9} sm={12} xs={12}
                    >
                      {selectedDevices && selectedDevices.length
                        ? (
                          <UpdateCalendar

                            startDate={dates.start}
                            endDate={dates.end}
                            handleRangeChange={handleRangeChange}
                            handleQuickOptionsChange={handleQuickOptionsChange}
                            selectedQuickOption={selectedQuickOption}
                          />
                        )
                        : ' '}

                    </Grid>


                }

                {/* </Grid> */}
                <Grid item xl={9} lg={8} md={7} sm={12} xs={12}
                // style={{ height: '90vh' }}
                >
                  <div>
                    {(!selectedDevices.length)
                      ?
                      <div>
                        <img src="../../img/deviceSelect2.png" alt="Select device" width="50%" ></img>
                      </div>
                      //  'Please Select a device'


                      : (<Devicecontainer selecteddevice={selectedDevices} newdates={dates} />)}

                  </div>
                </Grid>

              </Grid>
            </div>
          </Grid>
      }
    </>

  );
}
export default withStyles(styles)(DeviceView);
