import React, { useState, useContext } from "react";
import { withStyles } from "@mui/styles";
// import assetDetails from './assetDetails';
import UpdatedChamberCard from "../../components/UpdatedChamberCard";
import Paper from "@mui/material/Paper";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import UserContext from 'Context/UserContext';
import { CircularProgress } from '@mui/material';



import API from "API/apiConfig";
const APIURL = process.env.REACT_APP_APIURL;

import {
  Grid,
} from "@mui/material";
import { useEffect } from "react";


const styles = {
  chipGrid: {
    textAlign: 'right',
    justifyContent: 'right',
    // marginTop:'1rem'
  },
  assetGroupContainer: {
    display: "block",
    padding: "10px 10px",

  },
  assetGroupHeader: {
    textAlign: "left",
    // paddingLeft: '15px',
    marginTop: "5px",
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: '900',
    padding: '0.7rem',
    background: "rgba(238, 245, 253, 0.69)",
    border: "1px solid rgba(70, 142, 227, 0.4)",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "16px"

  },
  cardDiv: {
    display: "flex",
    flexWrap: "wrap",
    // backgroundColor: '#fff',
    width: '100%',
    borderRadius: "22px",
    padding: "25px 10px",
  },
  emptyPaper: {
    marginTop: '10%'
  }
}

const Detail = (props) => {

  const { classes, date, searchId, chipFilters, menuItem, warehouseType, chamberType, handlchipInitialstateforDetail, refreshData, filterAssetGroup, filterAsset } = props;
  // console.log('Props in detail ', props,chipFilters, menuItem )
  const history = useHistory();
  const { updateSingleChamber, companySettings, CompanyInfo, updateAssetGroupList } = useContext(UserContext);

  const [assetGroupDetail, setassetGroupDetail] = useState([])
  const [assetGroupDetailCopy, setassetGroupDetailCopy] = useState([])
  const [assetGroupDetailCopy1, setassetGroupDetailCopy1] = useState([])
  // const [assetGroupDetailCopy2, setassetGroupDetailCopy2] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [tempState,setTempState]=useState([])
  


  //   const getAssetDetail=()=>{
  //     const startInUnix=moment(date[0]).unix()
  //     const endInUnix=moment(date[1]).unix()
  //     console.log('startInUnix',startInUnix ,endInUnix)
  //     setIsLoading(true)
  //     API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}`)
  //     .then((response)=>{
  //         const resp= response.data.assetGroups
  //         console.log('response', resp)

  //         if (response.data && response.data.status && response.data.status === 'success') {
  //             console.log('response1', response.data.assetGroups, )
  //             setassetGroupDetail(response.data.assetGroups)
  //             setassetGroupDetailCopy(response.data.assetGroups)
  //             setIsLoading(false);
  //           } else {
  //             // cogoToast.error('unable to get Client(s)', { position: 'top-right' });
  //             setIsLoading(false);
  //           }

  //     })
  //     .catch((err)=>{
  //         console.log('Err', err)
  //     })
  // }


  const getAssetGroupDetail = () => new Promise((resolve, reject) => {

    const startInUnix = moment(date[0]).unix()
    const endInUnix = moment(date[1]).unix()

    setIsLoading(true)
    API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${false}`)
      .then((response) => {
        if (response.data.status === 'success') {


          resolve(response.data.assetGroups);

        } else {

          // setIsLoading(false)
          reject(new Error('Error loading warehouses'));
        }
      })
      .catch((err) => {

        reject(new Error(err));
        setIsLoading(false)

      });
  })

  const getAssetStatus = (response) => {

    const assetGroupWithAssetStatus = response.map((ag) => {
      if (ag.assets && ag.assets.length) {
        ag.assets.map((ast) => {
          // const newAsset = { ...ast };
          if (ast && ast.devices && ast.devices.length) {
            ast.devices.forEach((device) => {

              // ast.is_Violated=false; ast.is_Warned=false;
              // if (device && device.violations && device.violations.temperature > 0)
              const tempLastPing = device.lastPing && device.lastPing.t1 && device.lastPing.t1[device.lastPing.t1.length - 1];
              const humidityLastPing = device.lastPing && device.lastPing.humidity && device.lastPing.humidity[device.lastPing.humidity.length - 1];
              const batteryLastPing = device.lastPing && device.lastPing.battery;

              let dummyObjVIO = {
                temperature: null,
                humidity: null,
                battery: null
              }
              let dummyObjWAR = {
                temperature: null,
                humidity: null,
                battery: null
              }

              const isTempVio = (Number(tempLastPing) > Number(ast.temperatureSettings && ast.temperatureSettings.maxViolation))
                || (Number(tempLastPing) < Number(ast.temperatureSettings && ast.temperatureSettings.minViolation));

              const isTempWar = Number(tempLastPing) > Number(ast.temperatureSettings && ast.temperatureSettings.maxWarning)
                || Number(tempLastPing) < Number(ast.temperatureSettings && ast.temperatureSettings.minWarning)

              const isHumidityVio = (Number(humidityLastPing) > Number(ast.humiditySettings && ast.humiditySettings.maxViolation))
                || (Number(humidityLastPing) < Number(ast.humiditySettings && ast.humiditySettings.minViolation));

              const isHumidityWar = Number(humidityLastPing) > Number(ast.humiditySettings && ast.humiditySettings.maxWarning)
                || Number(humidityLastPing) < Number(ast.humiditySettings && ast.humiditySettings.minWarning);

              const isBatteryVio = Number(batteryLastPing) < Number(ast.batterySettings && ast.batterySettings.critical) ||
                Number(batteryLastPing) < 10

              const isBatteryWar = Number(batteryLastPing) < Number(ast.batterySettings && ast.batterySettings.warning) ||
                Number(batteryLastPing) < 25


              if (isTempVio || isHumidityVio || isBatteryVio) {
                ast.is_Violated = true;
              }
              if (isTempVio) {
                dummyObjVIO = { ...dummyObjVIO, temperature: 1 }
                device.VIOLATION = dummyObjVIO
              }
              if (isHumidityVio) {
                dummyObjVIO = { ...dummyObjVIO, humidity: 1 }
                device.VIOLATION = dummyObjVIO

              }
              if (isBatteryVio) {
                dummyObjVIO = { ...dummyObjVIO, battery: 1 }
                device.VIOLATION = dummyObjVIO
                // device.VIOLATION={...device.VIOLATION, battery: 1}
              }

              if ((!isTempVio && isTempWar) || (!isHumidityVio && isHumidityWar) || (!isBatteryVio && isBatteryWar)) {
                ast.is_Warned = true;
              }

              if (!isTempVio && isTempWar) {
                dummyObjWAR = { ...dummyObjWAR, temperature: 1 }
                device.WARNING = dummyObjWAR

              }
              if (!isHumidityVio && isHumidityWar) {
                dummyObjWAR = { ...dummyObjWAR, humidity: 1 }
                device.WARNING = dummyObjWAR

              }
              if (!isBatteryVio && isBatteryWar) {
                dummyObjWAR = { ...dummyObjWAR, battery: 1 }
                device.WARNING = dummyObjWAR

              }


            })
          }
          return ast;
        })
      } else {
        return ag
      }
      return ag
    })
    return assetGroupWithAssetStatus
  }



  const getInitialData = () => {
    getAssetGroupDetail()
      .then((response) => {

        const assetsWithStatus = getAssetStatus(response)
        updateAssetGroupList(assetsWithStatus);
        setassetGroupDetail(assetsWithStatus)
        setassetGroupDetailCopy(assetsWithStatus)
        // setassetGroupDetailCopy2(assetsWithStatus)  // not sure
        setIsLoading(false);

      })
      .catch((err) => {
        console.log('Err**', err)
        setIsLoading(false);
        // setassetGroupDetail([])
        //  setassetGroupDetailCopy([])
      })
  }



  


  const handleSearchInput = () => {
    //  console.log('chipFilters',chipFilters)
    if (searchId && searchId.length >= 1) {
      const arraytoMapOn = menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val) ? assetGroupDetailCopy1 : assetGroupDetailCopy

      let assetGroupSuggestions = [];
      const filterAssetsGp = arraytoMapOn
        .map((assestgp) => {
          if (
            assestgp.id
              .toLowerCase()
              .includes(searchId.toLowerCase())
          ) {
            // this solves,
            // warehouse doesn't show all chambers after we filter by chamber - as we are setting assets to particular searched asset below
            const arraytoMapOnforAssets = menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val) ? assetGroupDetailCopy1 : assetGroupDetail
            const alertAsset = arraytoMapOnforAssets.find((a) =>
              a.id
                .toLowerCase()
                .includes(assestgp.id.toLowerCase())
            );
            assestgp.assets = alertAsset.assets;
            assetGroupSuggestions = assestgp;
            return assetGroupSuggestions;
          }


          const filterassets =
            assestgp.assets &&
            assestgp.assets.filter((a) =>
              a.id.toLowerCase().includes(searchId.toLowerCase())
            );

          if (filterassets && filterassets.length) {
            //take the reference of assetgp and add filteredasset to that assetgp object and return that value.
            const assestgpCopy = { ...assestgp };

            assestgpCopy.assets = filterassets;
            return assestgpCopy;
          }


          return null;
        })
        .filter((m) => m);

      setassetGroupDetailCopy(filterAssetsGp)
    }
    //if we go back , no search is there 
    if (searchId.length === 0) {
      if (menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val)) {
        // if search is empty , check if any filter is applied or not .
        setassetGroupDetailCopy(assetGroupDetailCopy1)
      } else {
        setassetGroupDetailCopy(assetGroupDetail)
      }


    }
  }






  const isViolatedOrWarned = (violationsOrWarnings, type,) => {

    return violationsOrWarnings && violationsOrWarnings[type] > 0;

  }


  const handleChipFilters = (allAssetsGroup, filterArray) => {
    if (filterArray && filterArray.length) {
        
      const assetWithVio = allAssetsGroup && allAssetsGroup.length && allAssetsGroup.map((ag) => {
        let filteredAssets = []
        if (ag.assets && ag.assets.length) {
          filteredAssets = ag.assets.filter((ast) => {
            if (ast.devices && ast.devices.length) {
              const isViolated = ast.devices.some((dev) => {
                return filterArray.some(type => {
                  if (menuItem === "Violations") {

                    return isViolatedOrWarned(dev.VIOLATION, type)

                  }
                  if (menuItem === "Warnings") {

                    return isViolatedOrWarned(dev.WARNING, type)

                  }
                  if (menuItem === "All Alerts") {
                    return isViolatedOrWarned(dev.VIOLATION, type) || isViolatedOrWarned(dev.WARNING, type)

                  }


                })
              })

              return isViolated
            }

          })
        }
        return { ...ag, assets: filteredAssets }

      }).filter(ag => ag && ag.assets && ag.assets.length)

    
      return assetWithVio;
    }

  }

  const handleAlertstype = (allAssetsGp, status) => {

    if (status === "Violations") {

      const assetWithVio = allAssetsGp && allAssetsGp.length && allAssetsGp.map((ag,) => {
        if (ag.assets && ag.assets.length) {
          const filteredAssets = ag.assets.filter((ast) => ast.is_Violated === true)
          if (filteredAssets && filteredAssets.length) {
            const copy = { ...ag }
            copy.assets = filteredAssets
            return copy;
          }
          return null;

        }
      }).filter((m) => m);
      return assetWithVio;
    }


    if (status === "Warnings") {

      const assetWithVio = allAssetsGp.map((ag,) => {
        if (ag.assets && ag.assets.length) {
          const filteredAssets = ag.assets.filter((ast) => (ast.is_Warned === true))
          if (filteredAssets && filteredAssets.length) {
            const copy = { ...ag }
            copy.assets = filteredAssets
            return copy;
          }
          return null;

        }
      }).filter((m) => m);
      return assetWithVio;
    }
    if (status === "All Alerts") {

      return allAssetsGp
    }


  }

  const handleAllFilters = () => {
    const filtersArr = Object.keys(chipFilters);
    const trueFilters = filtersArr.filter((filter) => chipFilters[filter] === true);
   
    let allAssetsDetails =  assetGroupDetail // original data

    if (trueFilters && trueFilters.length) {
      allAssetsDetails = handleChipFilters(allAssetsDetails, trueFilters);

    }
    if (menuItem && menuItem.length) {

      allAssetsDetails = handleAlertstype(allAssetsDetails, menuItem)

    }
     
    setassetGroupDetailCopy(allAssetsDetails)  //after getting value we are changing the copy and showing that 
    setassetGroupDetailCopy1(allAssetsDetails)
    const assetgroupDetailCopyyy=allAssetsDetails && allAssetsDetails.length ? [...allAssetsDetails] :[]
    
    setTempState(assetgroupDetailCopyyy)
    // console.log('allAssetsDetails',allAssetsDetails)

  }


  const handleAssetGroupSelect=(assetgroupDetails,filterAssetGroup)=>{
    const FinalAssetGroups=assetgroupDetails.filter((ag)=>ag.id === filterAssetGroup )
  
    return FinalAssetGroups
  }

  const handleAssetSelect=(assetDetils,filterAsset)=>{
    const filterAssetsGp =  assetDetils
          .map((assestgp) => {
  
            const filterassets =
              assestgp.assets &&
              assestgp.assets.filter((a) =>
                a.id.toLowerCase().includes(filterAsset.toLowerCase())
              );
  
            if (filterassets && filterassets.length) {
              //take the reference of assetgp and add filteredasset to that assetgp object and return that value.
              const assestgpCopy = { ...assestgp };
  
              assestgpCopy.assets = filterassets;
              return assestgpCopy;
            }
  
  
            return null;
          })
          .filter((m) => m);

          return filterAssetsGp;
  }

const handleAutoSearchFilter=()=>{
 
  let assetgroupDetail=(menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val)) 
     ? (filterAssetGroup === null  || filterAsset === null ) ? tempState : 
     (filterAssetGroup !== null  || filterAsset !== null) ? tempState : assetGroupDetail
     :  assetGroupDetail
     

  // let assetgroupDetail = (menuItem !== "All Alerts" || Object.values(chipFilters).some(val => val)) 
  //  ? (filterAssetGroup === null || filterAsset === null) ?  tempState : assetGroupDetail   
  //  :  (filterAssetGroup === null && filterAsset === null ) ? assetGroupDetail 
  //  :  (filterAssetGroup !== null && filterAsset === null) ? assetGroupDetail  : assetGroupDetailCopy
    
   if(filterAssetGroup !== null){
    assetgroupDetail=handleAssetGroupSelect(assetgroupDetail,filterAssetGroup)
    }
  if(filterAsset !== null){
    assetgroupDetail=handleAssetSelect(assetgroupDetail,filterAsset)
  }
 
    setassetGroupDetailCopy(assetgroupDetail);
}

  useEffect(() => {

    // getAssetDetail();
    getInitialData()
  }, [refreshData])


  useEffect(() => {

    handleSearchInput()
  }, [searchId])

  


  useEffect(()=>{
    handleAutoSearchFilter()
    
  },[filterAssetGroup, filterAsset])

  useEffect(() => {

    handleAllFilters()

  }, [chipFilters, menuItem])






  const handleCardClick = (selectedAssetUid) => {


    // this.onDashboardStateChange();


    const startTime = moment().startOf('day').unix();
    const endTime = moment().unix();

    history.push(
      `/dashboard?assetUid=${selectedAssetUid}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`
    );

    updateSingleChamber(true);

    handlchipInitialstateforDetail({
      temperature: false,
      humidity: false,
      battery: false,
    })
  }

  // console.log('Live data called',assetGroupDetailCopy )
  return (
    <>

      {
        isLoading ?
          (
            <>
              <div
                style={{
                  position: "fixed",
                  display: "block",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: "2",
                }}
              >
                <CircularProgress
                  style={{ position: 'absolute', top: '50%', left: '50%' }}
                />
              </div>
            </>
          )
          :
          (
            <>

              <Grid container  >


                <div className={classes.cardDiv}>
                  <Grid container spacing={0} style={{ display: "block", height: '80vh', overflow: 'auto', }}>


                    {assetGroupDetailCopy && assetGroupDetailCopy.length ? (
                      assetGroupDetailCopy.map((ag) => (

                        <div key={ag.uid} className={classes.assetGroupContainer}>
                          <h3 className={classes.assetGroupHeader}>
                            {" "}
                            {warehouseType ? warehouseType : "Warehouse"}

                            {" : "}
                            <span style={{ color: 'rgba(0,0,0,0.7)' }} >{ag.id ? ag.id : ' '}{" "}</span>
                          </h3>
                          <Grid container spacing={2} style={{ marginLeft: '-25px' }} >
                            {/* spacing=5 */}

                            {ag.assets && ag.assets.length ? (
                              ag.assets.map((asset) => (

                                <Grid
                                  item
                                  // style={{height:'14rem'}}  //to give height to chamber card
                                  md={6}
                                  xl={3}
                                  lg={4}
                                  sm={6}
                                  xs={12}
                                  key={`allAssets${asset.uid}`}
                                >
                                  <UpdatedChamberCard

                                    warehouse={asset.assetGroupId}
                                    chamberName={asset.id ? asset.id : " "}
                                    chamberUid={asset.uid ? asset.uid : " "}
                                    maxTemp={
                                      asset.temperatureSettings &&
                                        Object.prototype.hasOwnProperty.call(asset.temperatureSettings, "maxViolation")
                                        // asset.temperatureSettings.hasOwnProperty(
                                        //   "maxViolation"
                                        // )
                                        ? asset.temperatureSettings
                                          .maxViolation
                                        : null
                                    }
                                    minTemp={
                                      asset.temperatureSettings &&
                                        Object.prototype.hasOwnProperty.call(asset.temperatureSettings, "minViolation")
                                        // asset.temperatureSettings.hasOwnProperty(
                                        //   "minViolation"
                                        // )
                                        ? asset.temperatureSettings
                                          .minViolation
                                        : null
                                    }

                                    maxHumidity={
                                      asset.humiditySettings &&
                                        Object.prototype.hasOwnProperty.call(asset.humiditySettings, "maxViolation")
                                        // asset.humiditySettings.hasOwnProperty(
                                        //   "maxViolation"
                                        // )
                                        ? asset.humiditySettings.maxViolation
                                        : null
                                    }
                                    minHumidity={
                                      asset.humiditySettings &&
                                        Object.prototype.hasOwnProperty.call(asset.humiditySettings, "minViolation")
                                        // asset.humiditySettings.hasOwnProperty(
                                        //   "minViolation"
                                        // )
                                        ? asset.humiditySettings.minViolation
                                        : null
                                    }
                                    minBattery={
                                      asset.batterySettings &&
                                        Object.prototype.hasOwnProperty.call(asset.batterySettings, "critical")
                                        ? asset.batterySettings.critical
                                        : null
                                    }
                                    maxBattery={
                                      asset.batterySettings &&
                                        Object.prototype.hasOwnProperty.call(asset.batterySettings, "warning")
                                        ? asset.batterySettings.warning
                                        : null
                                    }


                                    devices={asset.devices}
                                    temperatureSettings={asset.temperatureSettings ? asset.temperatureSettings : {}}
                                    humiditySettings={asset.humiditySettings ? asset.humiditySettings : {}}
                                    batterySettings={asset.batterySettings && Object.keys(asset.batterySettings).length ? asset.batterySettings : { critical: 10, warning: 25 }}
                                    violated={asset.violated}
                                    warning={asset.warning}
                                    onClickChamber={handleCardClick}
                                    companySettings={companySettings}
                                    warehouseType={warehouseType}
                                    chamberType={chamberType}
                                    notificationSetting={asset.notificationSettings ? asset.notificationSettings : {}}
                                    allAssets={ag.assets}
                                    is_Violated={Object.prototype.hasOwnProperty.call(asset, "is_Violated") ? asset.is_Violated : false}
                                    is_Warned={Object.prototype.hasOwnProperty.call(asset, "is_Warned") ? asset.is_Warned : false}
                                    CompanyInfo={CompanyInfo}
                                  />
                                </Grid>
                              ))
                            ) : (
                              <p style={{ margin: 'auto', padding: '2rem' }}>
                                {" "}
                                No {chamberType ? chamberType : "Chamber"} found
                              </p>
                            )}
                          </Grid>
                        </div>


                      ))
                    ) : (
                      <Paper elevation={0} className={classes.emptyPaper}>
                        {
                          <h4 className={classes.emptyHeading}>
                            {" "}


                            <img src="../../img/emptybox.png" alt="empty" style={{ margin: 'auto', display: 'flex' }}></img>
                            No {warehouseType ? warehouseType + " " : "Warehouse "}found

                          </h4>
                        }
                        {/* {(filterBy === ('assetGroupId' || 'assetId'))
                                    ? <h4 className={classes.emptyHeading}> No {warehouseType ? warehouseType : 'Warehouse'} found </h4>
                                    : <h4 className={classes.emptyHeading}> No {chamberType ? chamberType : 'Chamber'} found </h4>} */}
                      </Paper>
                    )}
                  </Grid>
                </div>

              </Grid>
            </>
          )
      }


    </>


  )
}








export default withStyles(styles)(Detail);


