import { withStyles } from "@mui/styles";
import React, {useState} from "react";
import { DialogTitle, Grid, Paper, Tooltip, Typography, } from "@mui/material";
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import PrimaryButton from "components/PrimaryButton";
import TextField from "@mui/material/TextField";
import AutoField from "components/AutoField";
import { Dialog,} from "@mui/material";
// import DialogContent from '@mui/material/DialogContent';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import API from "API/apiConfig";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cogoToast from 'cogo-toast';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect } from "react";
const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  outerGrid: {
    padding: "2rem 0rem 2rem 0rem",

  },
  innergrid: {
    overflow: 'auto',
    paddingBottom: '2rem',
    justifyContent: 'center'
  },
  newline: {
    whiteSpace: "pre-line",
    padding: '1rem'

  },
  stepsGrid: {
    cursor: 'pointer',
    border: '1px solid rgba(0,0,0,0.1)',
    "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(169,208,208)",
    },
  },
  stepdetail: {
    padding: '0.8rem',

  },
  disableArrow:{
    color:'rgba(0,0,0,0.3)',
    fontSize:'7rem'
  },
  activeArrow:{
    color:'#008081',
    fontSize:'7rem'
  }

}

const steps = [
  {
    id: 1,
    stepImg: "./../img/step1.png",
    stepText: `Download the google home app.`,
    frame:"./../img/Stepframe1.png"
  },
  {
    id: 2,
    stepImg: "./../img/step2.png",
    stepText: `Create a home for quick access.`,
   frame:"./../img/Stepframe2New.png"
  },
  {
    id: 3,
    stepImg: "./../img/step3.png",
    stepText: `Connect the Google Nest Hub to a WI-fi network.`,
    frame:"./../img/Stepframe3New.png"
  },
  {
    id: 4,
    stepImg: "./../img/step4.png",
    stepText: 'Link Pixel portal to Nest Hub.',
    frame:"./../img/Stepframe4New.png"
  },
  {
    id: 5,
    stepImg: "./../img/step5.png",
    stepText: 'Add device to Google home app.',
    frame:"./../img/Stepframe5New.png"
  },
  {
    id: 6,
    stepImg: "./../img/step6.png",  
    stepText: `Enable notifications to get voice alert.`,
    frame:"./../img/Stepframe6New.png"
  },

]

const NestSteps = (props) => {
  const { classes,selectedAGList, users } = props;
  const [deviceuid, setDeviceUid]= useState({});
  const [openNestDetailDialog, setOpenNestDetailDialog]=useState(false);
const [stepdetail, setStepDetail]=useState({});
const [activeStepNo, setActiveStepNo] = React.useState();

  let selectDeviceList=[];
  selectedAGList.map((lst)=>{
    lst.assets && lst.assets.length && lst.assets.map((ast)=>{
      if(ast.isSmartUserAssigned){
        ast.devices && ast.devices.length && ast.devices.map((dev)=>{
          const id= dev.id
          selectDeviceList.push(id)
        })
      }
      
    })
  })

 const handledeviceChange=(e,value)=>{
 
const selectedDevice=value;

// match the selected id and get its uid
const uidofSelectedDevice=selectedAGList.map((ag)=>{
  let a;
  ag.assets && ag.assets.length && ag.assets.map((ast)=>{
      ast.devices && ast.devices.length && ast.devices.map((dev)=>{
          if( dev.id ===  selectedDevice){
              a ={ id:dev.uid , emailId:ag.emailId === undefined ?  users : ag.emailId  }
          }
      }
      )
  })
  return a
}).filter(ag=>ag)

setDeviceUid(...uidofSelectedDevice);

 }

const  handleStepdetails =(params)=>{
 
  // setOpenNestDetailDialog(true);
  // setStepDetail(params);
  // setActiveStepNo(params);
  setOpenNestDetailDialog(true)
  setActiveStepNo(params);
}

useEffect(()=>{
 if(activeStepNo !== undefined){
  let activeFrame;
   steps.map((s)=>{
      if(s.id === activeStepNo){
        activeFrame={id:s.id, frame:s.frame}
      }
    })
    setStepDetail(activeFrame)
 }
  
},[activeStepNo])

const handleNestdetailDialogClose=()=>{
  setOpenNestDetailDialog(false);
  setStepDetail({});
setActiveStepNo()
}

const handlePrevStep=()=>{
  if(activeStepNo > 1 ){
    const newActiveStep =activeStepNo - 1 ;
    setActiveStepNo(newActiveStep);
  }
}

const handleNextStep=()=>{
  if(activeStepNo < 6){
    const newActiveStep =activeStepNo +1 ;
    setActiveStepNo(newActiveStep);
  }
 
}


// 'https://pixelapidev.adaptideations.com/api/googletestnotificationdevice?uid=f412fa58e5cc&emailIds[]=merk.fvtp@outlook.com' \

  const handleNotificationTest=()=>{
    // console.log('deviceuid',deviceuid)
    API.get(`${APIURL}/googletestnotificationdevice?uid=${deviceuid.id}&&emailIds[]=${deviceuid.emailId}`)
    .then((res)=>{
        // console.log('res', res)
        if(res && res.data && res.data.status === "success"){ 
        
            cogoToast.success('Notification sent successfully', { position: 'top-right' });
        } else{
            const errMsg=res.data && res.data.message
            cogoToast.error(errMsg, {
                position: 'top-right',
              });
        }
    })
    .catch((err)=>{
        const errMsg=err.data.data.message
  
        cogoToast.error(errMsg, {
            position: 'top-right',
          });
    })

}

  return (
    <div  >
      {/* <Grid  justifyContent='center'  className={classes.outerGrid}> */}
      {/* --------------Steps to follow-------------------- */}
      <Grid container className={classes.outerGrid} justifyContent="center" spacing={2}>
        {steps.map((value) => (
          <Grid key={value} item
          // xs={2}
          >
            <Paper
              className={classes.stepsGrid}
              // onClick={handleStepdetails()}
              // onClick={()=>{handleStepdetails({id:value.id,frame:value.frame} )}}
              onClick={()=>{handleStepdetails(value.id)}}
              sx={{

                height: 205,
                width: 160,
              }}
            >
              <Grid container>

                <Grid item xs={4} >
                  <img src={value.stepImg} alt="step1" width="100%" style={{ position: 'relative', right: '0.8rem' }} />
                </Grid>
                <Grid item xs={8} sx={{ margin: 'auto' }} >
                  <Typography variant="p" sx={{ fontSize: '20px', fontFamily: 'Montserrat' }}>Step</Typography>
                </Grid>
                <Grid item xs={12} className={classes.stepdetail}>
                  <Typography variant="p" sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}> {value.stepText}</Typography>
                </Grid>

              </Grid>

            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid>
      To see the  google nest setup in detail <a href={"https://adapt29.trainn.co/share/1IT4xX5wUOzaHTpq8ZaBbw/embed?autoplay=false"} rel="noopener noreferrer" target="_blank">Click here</a>

      </Grid>

      {/* ----------------Test notification-------------- */}
      <div style={{paddingTop:'0.5rem'}}>
     <span  style={{  fontSize: '20px', fontFamily: 'Montserrat' }} >Note: </span>
     <Typography variant="p" sx={{ fontSize: '20px', color: 'rgba(0,0,0,0.7)',fontFamily: 'Montserrat', fontWeight: 500 }} >
     {`To check how google nest will send alert: select a device and click on "Check Voice Alert" button.`}
      </Typography>
      </div>
      <Grid container sx={{paddingBottom:'1.2rem'}}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AutoField
            style={{ width: '98%', height: '2.8rem', marginTop: '1em' }}
            disablePortal
            backgroundColor="#f2f2f2"

            id="users"
            // value={selectDeviceList}
            onChange={handledeviceChange}
            // options={selectDeviceList}
            options={selectDeviceList}
            getOptionLabel={option => option}
            filterSelectedOptions
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select device to test" />}
          />
        </Grid>
        <Grid item sx={{ p: '1rem' }}>
          <PrimaryButton
            variant="contained"
            text="Check Voice Alert"
            onClick={handleNotificationTest}

          />
        </Grid>
        <Grid item sx={{margin:'auto'}}>
          <Typography variant="p" sx={{ fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500 ,color: 'rgba(0,0,0,0.65)' }}>
              *After clicking, wait for 2-5 seconds to hear voice alert.
          </Typography>
        </Grid>

              {/* -----------------------Steps Detail------------------------ */}
              <Dialog
                open={openNestDetailDialog}
                TransitionComponent={Zoom}
                // onClose={this.onDeleteDialogClose}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                scroll="body"
            >
                
                <DialogTitle>
                <Grid sx={{textAlign:'right', height:'1rem'}} >
                  <Tooltip title="Close" >
                            <IconButton
                                onClick={handleNestdetailDialogClose}
                                className={classes.icon}
                            >
                                <CloseIcon />
                            </IconButton>
                            </Tooltip>
                        </Grid>
                </DialogTitle>
                <Grid container>
                  <Grid item xs={0.5} sx={{margin:'auto', textAlign:'left'}}>
                    <IconButton
                     onClick={handlePrevStep} 
                      disabled={activeStepNo === 1}
                      
                      >
                        <Tooltip title="Previous step">
                          <ArrowBackIosNewIcon 
                      className={activeStepNo === 1 ? classes.disableArrow :classes.activeArrow }/>
                      </Tooltip>
                      </IconButton>
                      </Grid>
                  <Grid item xs={11} > <img src={stepdetail.frame} alt="step1" width="100%"  /></Grid>
                  <Grid item xs={0.5} sx={{margin:'auto',textAlign:'right' }}>
                  <Tooltip title="Next step">
                    <IconButton onClick={handleNextStep}  
                    disabled={activeStepNo === 6}
                   
                    >
                      
                       <ArrowForwardIosIcon 
                    className={activeStepNo === 6 ? classes.disableArrow :classes.activeArrow }
                     />
                    </IconButton>
                    </Tooltip>
                    </Grid>
                </Grid>
                
            </Dialog>

      </Grid>


      {/* </Grid> */}

    </div>
  )
}
export default withStyles(styles)(NestSteps)



