import React, { useState, useContext } from "react";
import { DialogActions, DialogContent, IconButton, } from '@mui/material';
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import { Grow } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import SecondaryButton from "components/SecondaryButton";
import PrimaryButton from "components/PrimaryButton";
import API from "API/apiConfig";
import ReactSelect from 'react-select';
import moment from "moment/moment";
import FormControl from '@mui/material/FormControl';
import SelectField from 'components/SelectField';
import MenuItem from '@mui/material/MenuItem';
import UserContext from 'Context/UserContext';
import DateAndTimeRangePicker from "components/DateAndTimeRangePicker";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import cogoToast from "cogo-toast";
import { CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useEffect } from "react";
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const APIURL = process.env.REACT_APP_APIURL;


const styles = {
    title: {
        justifyContent: 'left',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: '700',
    },
    textstyle: {
        // margin:'auto',
        textAlign: 'left'
    },
    formControl: {
        // minWidth: '100%',
        width: '16rem',
        margin: 'auto'
    },
    gridStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5rem'
    },
    buttonStyle: {
        // paddingTop:'0.3rem'
    },
    noChamberList: {
        display: 'flex',
        justifyContent: 'center'
    },
    downloadDiv: {
        fontFamily: 'Montserrat',
        padding: '1rem',
        fontSize: '12px'
    }
}

const selectStyles = {
    control: () => ({
        alignItems: 'center',
        backgroundColor: 'hsl(0,0%,100%)',
        // marginTop: '1rem',
        borderRadius: '21px',
        width: '16rem',
        borderBottom: '1px solid #dedede',
        cursor: 'default',

        display: 'flex',

        flexWrap: 'wrap',

        justifyContent: 'space-between',
        minHeight: '38px',
        outline: '0 !important',
        position: 'relative',

        transition: 'all 100ms',
        boxSizing: 'border-box',
    }),
};

const GlobalDownload = (props) => {
    const { classes, assetgp, warehouseType, chamberType } = props;
    const userDetails = useContext(UserContext);
    const { CompanyInfo } = userDetails;
    const timeZones = moment.tz.names();
    const tz = moment.tz.guess();
    const index = timeZones.indexOf(tz);

    const timeZoneOptions = timeZones.map((item) => ({ label: `(GMT${moment.tz(item).format('Z')})${item}`, value: item }));

    // const [assetswithDevices, setAssetswithDevices]=useState([])
    const [selectedOption, setSelectedOptions] = useState(timeZoneOptions[index])
    const [reportdialog, setReportDialog] = useState(false)
    // const [timezone, setTimezone]= useState(timeZoneOptions)
    const [frequency, setFrequency] = useState(15)
    const [dataType, setDataType] = useState("all")
    const [reportType, setReportType] = useState('pdf')
    const [date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()])
    const [selectedQuickOption, setSelectedQuickOption] = useState(1)
    // const [assets, setAssets] = useState([])
    const [selectedAssets, setSelectedAssets] = useState([])
    // const [itemsChecked, setItemsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableMenu, setDisableMenu] = useState(false);

    const [assetsGroupList, setAssetGroupList] = useState([]);
    const [isEvents , setisEvents]=useState(1);
    // const [studentDetail, setStudentDetail]= useState([]);

      // comparing with length for hotfix , we can make it better
   const hideDataTypeOption=CompanyInfo && 
   CompanyInfo.settings && CompanyInfo.settings.data &&  CompanyInfo.settings.data.humidity && 
   CompanyInfo.settings.data.humidity.disableDeviceTypes ?
   CompanyInfo.settings.data.humidity.disableDeviceTypes.length === 4 : false

    // const isQTS = CompanyInfo && ["QTS", "FTVP"].includes(CompanyInfo.companyId);


    const handleReactSelectChange = (selectedOption) => {
        setSelectedOptions(selectedOption)
    };


    const handleDialogOpen = () => {
        const startInUnix = moment(date[0]).unix()
        const endInUnix = moment(date[1]).unix()
        setLoading(true)
        API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${false}`)
            .then((response) => {
                if (response.data.status === 'success') {

                    const assetsGroup = response.data.assetGroups;
                    // console.log('Res',assetsGroup)
                    setAssetGroupList(assetsGroup)
                    setReportDialog(true)
                    setDisableMenu(true)
                    setSelectedAssets([])
                    setLoading(false)
            
                }

            })
            .catch((err) => {
                console.log('Err', err)
            })
       

    }



    // useEffect(() => {

    //     const filterAssetsGroup = assetsGroup.map((ag,) => {
    //         let filterAssets = [];
    //         // let newAg;
    //         if (ag.assets && ag.assets.length) {
    //             filterAssets = ag.assets.filter((ast) => {
    //                 if (ast.devices && ast.devices.length)
    //                     return ast
    //             })


    //         }

    //         return { ...ag, assets: filterAssets }
    //     }).filter(f => f)
    //     console.log('filterAssetsGroup', filterAssetsGroup)
    //     // setAssetswithDevices(filterAssetsGroup)
    // }, [])

    // const getAssetGroupData = () => {
    //     ///api request send ...
    //     const startInUnix = moment(date[0]).unix()
    //     const endInUnix = moment(date[1]).unix()
    //     API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${false}`)
    //         .then((response) => {
    //             if (response.data.status === 'success') {

    //                 const assetsGroup = response.data.assetGroups;
    //                 // console.log('Res',assetsGroup)
    //                 setAssetGroupList(assetsGroup)

    //             }

    //         })
    //         .catch((err) => {
    //             console.log('Err', err)
    //         })
    // }

    useEffect(() => {
        // getAssetGroupData()
    }, [])

    const handleDialogClose = () => {
        setReportDialog(false)
        setDate([moment().startOf('day').toDate(), moment().toDate()]),
            setSelectedOptions(timeZoneOptions[index]),
            setFrequency(15),
            setDataType('all'),
            setReportType('pdf'),
            setisEvents(1)
            setSelectedAssets([])
    }

    const handleSelectChange = (value) => {
        setFrequency(value)
    }

    const handleDataTypeChange = (value) => {
        setDataType(value)
    }

    const handleReportTypeChange = (type) => {
        setReportType(type)
    }

    const handleOnDateClick = (params) => {

        const sD = params[0]
        const today = new Date().getDate()
        let startDate;
        let endDate;
        // const eD=moment(sD).subtract(1,'d').toDate()
        if (params && params[0].getDate() === today) {

            startDate = moment(sD).startOf('day').toDate()
            endDate = new Date()

        } else {
            startDate = moment(sD).startOf('day').toDate()
            endDate = moment(sD).endOf('day').toDate()
        }


        setDate([startDate, endDate]);
        setSelectedQuickOption(0);
    }

    // const handleindeterminate=(assetgpUid, assets)=>{

    //     let indeterminateState=false;


    //     //  const findAssetGp=assetsGroup.find((ag)=>ag.assetGroupUid === assetgpUid)
    // //     assetsGroup.map((ag)=>{
    // //    selectedAssets && selectedAssets.length &&
    // //    selectedAssets.map((selasset)=>{
    // //     if(ag.assets && ag.assets.length ===  selasset.assets.length){
    // //         indeterminateState=false
    // //     }else{
    // //         indeterminateState=true
    // //     }

    // //     })

    //     assetsGroup.map((ag)=>{
    //         if(assets.length !== ag.assets && ag.assets.length){
    //             indeterminateState=true
    //         }else{
    //             indeterminateState=false
    //         }
    //     })

    //     console.log('All Ag',assetsGroup)

    //     return indeterminateState;
    // }

    const handleAssetsSelection = (params) => {
        const { assetgpUid, assetUid, value } = params;
        const { checked } = value.target
        // if(selectedAssets && selectedAssets.length ){
        //     //remove that asset from that ag group object

        // }else{
        // create new object
        if (checked) {
            let obj;
            const arr = [...selectedAssets]
            const index = selectedAssets.findIndex((s) => s.assetgpUid === assetgpUid)
            //if same assetgp is present , then update assets of that 
            if (index > -1) {

                arr[index].assets.push(assetUid);

                setSelectedAssets(arr)
            }
            else {
                //else create a new obj 
                obj = {
                    assetgpUid: assetgpUid,
                    assets: [assetUid]
                }
                setSelectedAssets([...selectedAssets, obj])
            }
        } else {
            const arr = [...selectedAssets]
            const index = selectedAssets.findIndex((s) => s.assetgpUid === assetgpUid)
            if (index > -1) {
                //find index on unchecked asset 
                const assetList = arr[index].assets
                const assetIndex = assetList.findIndex((a) => a === assetUid);
                if (assetList.length > 0) { arr[index].assets.splice(assetIndex, 1) }
                if (assetList.length === 0) {

                    arr.splice(index, 1)
                }

                setSelectedAssets(arr)
            }
        }

    }





    const handleAssetGroupselection = (
        params,
        // e, ast
    ) => {
        const { assetgpUid, assets, value } = params
        const { checked } = value.target;
        if (!checked && selectedAssets && selectedAssets.length && selectedAssets.some((ag) => ag.assetgpUid === assetgpUid)) {
            const filterList = selectedAssets.filter((s) => s.assetgpUid !== assetgpUid)
            setSelectedAssets(filterList)

        } else {
            const obj = {
                assetgpUid: assetgpUid,
                assets: assets.map((ast) => ast.uid)
            }


            setSelectedAssets([...selectedAssets, obj]);

        }


    };

    const handleSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {

            const allAssetGroup = assetsGroupList.map((ag) => {
                let object;
                if (ag.assets && ag.assets.length) {
                    ag.assets.map(() => {
                        // if(ast.devices && ast.devices.length){

                        object = {
                            assetgpUid: ag.uid,
                            // assets:ag.assets.map((ast)=>{if(ast.devices && ast.devices.length)return ast.uid})
                            assets: ag.assets.map((ast) => ast.uid)
                        }


                        // }

                    })
                    return object;
                }
            }).filter(m => m)
            setSelectedAssets(allAssetGroup)

        } else {
            setSelectedAssets([])
            //empty selected Assset array..
        }
    }

    const handleExcelDownload = (timeZone, frequency, dataType) => {

        let ListOfAssetUid = []

        selectedAssets.forEach((ag) => {
            const assetuid = [];
            assetuid.push(...Object.values(ag.assets));
            ListOfAssetUid = [...ListOfAssetUid, ...ag.assets]
        })

        if (!selectedAssets.length) {
            cogoToast.error(chamberType ? "No " + chamberType + " selected" : 'No chamber selected', { position: 'top-right' });
        } else if (date && date.length === 2) {
            setLoading(true)

            const startTime = Math.round(Number(date[0].getTime() / 1000));
            const endTime = Math.round(Number(date[1].getTime() / 1000));
            let obj = {
                assetUids: ListOfAssetUid,
                timeZone: timeZone,
                dataInterval: frequency,
                startTime: startTime,
                endTime: endTime,
                reportType: 'excel'
            }

            // if data type option is not present , send temperature by dafault
            if (hideDataTypeOption)
            obj.dataType = 'temperature'
            if (dataType && ['temperature', 'humidity'].includes(dataType))
                obj.dataType = dataType

            API(
                {
                    url: `${APIURL}/bulkreport`,
                    method: 'POST',
                    headers: {
                        Accept: 'application/zip',
                    },
                    responseType: 'blob',
                    data: obj
                }
            )
                .then((response) => {
                    if (response.data) {
                        document.body.style.cursor = 'default';
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        let filename = `${'report'}`;
                        filename += '.zip';
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        setLoading(false)
                        setReportDialog(false)
                        setDate([moment().startOf('day').toDate(), moment().toDate()]),
                            setSelectedOptions(timeZoneOptions[index]),
                            setFrequency(15),
                            setDataType('all'),
                            setReportType('pdf'),
                            setisEvents(1);
                            setSelectedAssets([])

                    } else {
                        cogoToast.error('No report to download', {
                            position: 'top-right',
                        });
                        setLoading(false)
                    }
                })
                .catch(() => {
                    setLoading(false)
                    // console.error('Error downloading report ', err);
                });


        } else {
            cogoToast.error('Select Date range, not single date', {
                position: 'top-right',
            });
        }
    }

    const handleEventLogsChange=(value)=>{
        setisEvents(value);
    }

    const handlePDFDownload = (timeZone, frequency) => {
        let ListOfAssetUid = []


        selectedAssets.forEach((ag) => {
            const assetuid = [];
            assetuid.push(...Object.values(ag.assets));
            ListOfAssetUid = [...ListOfAssetUid, ...ag.assets]
        })


        if (!selectedAssets.length) {
            cogoToast.error(chamberType ? "No " + chamberType + " selected" : 'No chamber selected', { position: 'top-right' });
        } else if (date && date.length === 2) {
            setLoading(true)

            if (date.length === 2) {
                const startTime = Math.round(Number(date[0].getTime() / 1000));
                const endTime = Math.round(Number(date[1].getTime() / 1000));
                let obj = {
                    assetUids: ListOfAssetUid,
                    timeZone: timeZone,
                    dataInterval: frequency,
                    startTime: startTime,
                    endTime: endTime,
                    isEvents:isEvents,
                    reportType: 'pdf'
                }

                 // if data type option is not present , send temperature by dafault
                if (hideDataTypeOption)
                obj.dataType = 'temperature'
                if (dataType && ['temperature', 'humidity'].includes(dataType))
                    obj.dataType = dataType

                API({
                    url: `${APIURL}/bulkreport`,
                    method: 'POST',
                    headers: {
                        Accept: 'application/zip',
                    },
                    responseType: 'blob',
                    data: JSON.stringify(obj)
                })
                    .then((response) => {
                        if (response.data) {

                            const url = window.URL.createObjectURL(
                                new Blob([response.data]),
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            // const contentDisposition = response.headers['content-disposition'];
                            const filename = `${'report'}.zip`;
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            setLoading(false);
                            setReportDialog(false);
                            setDate([moment().startOf('day').toDate(), moment().toDate()]),
                                setSelectedOptions(timeZoneOptions[index]),
                                setFrequency(15),
                                setDataType('all'),
                                setReportType('pdf'),
                                setisEvents(1);
                                setSelectedAssets([]);


                        } else {
                            cogoToast.error('No report to download', {
                                position: 'top-right',
                            });
                            setLoading(false)
                        }
                    })
                    .catch(() => {
                        setLoading(false)
                        // console.error('Error downloading report ', err);
                    });
            }


        } else {
            cogoToast.error('Select Date range, not single date', {
                position: 'top-right',
            });
        }
    }

    // const customDownload = () => {
        
    // }


    return (
        <>

            <Grid className={classes.buttonStyle}>
                <Tooltip title="Download Report">
                    {/* <IconButton onClick={() => { isQTS ? customDownload() : handleDialogOpen(assetgp) }}> */}
                    <IconButton onClick={() => { handleDialogOpen(assetgp) }}>
                        <FileDownloadOutlinedIcon sx={{ color: '#008081', fontSize: '1.8rem' }} />

                    </IconButton>
                </Tooltip>



            </Grid>

            {
                loading ?
                    (<>
                        <div 
                         style={{
                            position: "fixed",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            backgroundColor: "rgba(0,0,0,0.5)",
                            zIndex: "2",
                          }}>
                            <CircularProgress
                                style={{ position: 'absolute', top: '50%', left: '50%' }}
                            />
                        </div>
                    </>) :
                    (
                        <Dialog
                            open={reportdialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="sm"
                            fullWidth
                            scroll="body"
                            TransitionComponent={Grow}
                            PaperProps={{
                                style: {
                                    backgroundColor: '#f2f2f2',
                                },
                            }}
                        >
                            <DialogTitle id="alert-dialog-title"
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: 900
                                }}
                            >
                               Report
                            </DialogTitle>
                            <DialogContent >
                                <Grid container className={classes.gridStyle}  >
                                    <Grid className={classes.textstyle}>
                                        Duration
                                    </Grid>
                                    <Grid>
                                        <DateAndTimeRangePicker
                                            date={date}
                                            onChange={handleOnDateClick}
                                            selectedQuickOption={selectedQuickOption}

                                            disableMenu={disableMenu}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.gridStyle} >
                                    <Grid className={classes.textstyle}>
                                        Time Zone
                                    </Grid>
                                    <Grid>
                                        <ReactSelect
                                            value={selectedOption}
                                            onChange={handleReactSelectChange}
                                            options={timeZoneOptions}
                                            styles={selectStyles}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.gridStyle}>
                                    <Grid className={classes.textstyle}>
                                        Data Interval
                                    </Grid>
                                    <Grid>
                                        <FormControl className={classes.formControl}>

                                            <SelectField
                                                variant="standard"
                                                value={frequency}
                                                onChange={(event) => {
                                                    handleSelectChange(event.target.value);
                                                }}
                                                inputProps={{
                                                    name: "Frequency",
                                                    id: "frequency",
                                                }}
                                                backgroundColor="#fff"
                                            >
                                               
                                                <MenuItem value={15}>15 min</MenuItem>
                                                <MenuItem value={30}>30 min</MenuItem>
                                                <MenuItem value={60}>1 hr</MenuItem>
                                            </SelectField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {
                                       !hideDataTypeOption && (
                                        <>
                                         <Grid container className={classes.gridStyle} >
                                    <Grid className={classes.textstyle}>
                                        Data Type
                                    </Grid>
                                    <Grid >
                                        <FormControl className={classes.formControl}>

                                            <SelectField
                                                variant="standard"
                                                value={dataType}
                                                onChange={(event) => {
                                                    handleDataTypeChange(event.target.value);
                                                }}
                                                inputProps={{
                                                    name: "DATA TYPE",
                                                    id: "dataType",
                                                }}
                                                backgroundColor="#fff"
                                            >
                                                <MenuItem value="all">All</MenuItem>
                                                <MenuItem value="temperature">Only Temperature</MenuItem>
                                                <MenuItem value="humidity">Only Humidity</MenuItem>
                                            </SelectField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                        </>
                                       )
                                }
                               
                                {/* event logs option */}
                                <Grid container className={classes.gridStyle}  >
                                    <Grid   className={classes.textstyle}>
                                        Download report
                                    </Grid>
                                    < Grid >
                                        <FormControl className={classes.formControl}>

                                            <SelectField
                                                variant="standard"
                                                value={isEvents}
                                             
                                                onChange={(event) => {
                                                    handleEventLogsChange(event.target.value);
                                                }}
                                                inputProps={{
                                                    name: "EVENT TYPE",
                                                    id: "isEvents",
                                                }}
                                                backgroundColor="#fff"
                                            >

                                                <MenuItem value={1}>With Event Logs</MenuItem>
                                                <MenuItem value={0}>Without Event Logs</MenuItem>
                                            </SelectField>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {
                                    CompanyInfo && CompanyInfo.settings.enableExcelDownload ? (
                                        <Grid container className={classes.gridStyle} >
                                            <Grid className={classes.downloadDialog}>
                                                Report Type
                                            </Grid>
                                            <Grid >
                                                <FormControl className={classes.formControl}>

                                                    <SelectField
                                                        variant="standard"
                                                        value={reportType}
                                                        onChange={(event) => {
                                                            handleReportTypeChange(event.target.value);
                                                        }}
                                                        inputProps={{
                                                            name: "REPORT TYPE",
                                                            id: "reportType",
                                                        }}
                                                        backgroundColor="#fff"
                                                    >
                                                        <MenuItem value="pdf">PDF</MenuItem>
                                                        <MenuItem value="excel">EXCEL</MenuItem>
                                                    </SelectField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    ) :
                                        null
                                }

                                {/* Chamber List */}


                                <Grid >
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid>
                                            <h3>{warehouseType ? warehouseType + " List" : 'Warehouse(s) List'}</h3>
                                        </Grid>
                                        <Grid style={{ paddingTop: '10px' }}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        // checked={itemsChecked}
                                                        onChange={handleSelectAll}
                                                        style={{ color: '#008081' }}
                                                    // onChange={handleSelectall} 
                                                    />
                                                } label="Select all" />

                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid style={{ height: '15rem' }}>
                                    {
                                        assetsGroupList && assetsGroupList.length ?
                                            assetsGroupList.map((ag) => (

                                                <List
                                                    key={ag.assetGroupUid}
                                                    sx={{ width: '100%', }}
                                                    aria-label="contacts"
                                                >


                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox

                                                                                // indeterminate={handleindeterminate(ag.assetGroupUid, ag.assets)}
                                                                                // indeterminate={(e)=>handleindeterminate({value: e, assetgpUid: ag.assetGroupUid, assets: ag.assets })}

                                                                                checked={selectedAssets && selectedAssets.length && selectedAssets.some((sel) => sel.assetgpUid === ag.uid)}
                                                                                onChange={(e) => { handleAssetGroupselection({ value: e, assetgpUid: ag.uid, assets: ag.assets }) }}
                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#008081' } }}
                                                                            />}

                                                                    />

                                                                </FormGroup>
                                                            </ListItemIcon>
                                                            <ListItemText   >
                                                                <span style={{ fontWeight: '900' }} key={ag.assetGroupUid}>{ag.id}</span>
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    {
                                                        ag.assets && ag.assets.length ?

                                                            ag.assets.map((ast) => (

                                                                <List
                                                                    style={{ marginLeft: '1rem' }}
                                                                    key={ast.uid} component="div" disablePadding>
                                                                    <ListItemButton sx={{ pl: 4 }}>
                                                                        <ListItemIcon><FormGroup>

                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={selectedAssets && selectedAssets.length && selectedAssets.some((sel) => sel.assets.includes(ast.uid))}
                                                                                        onChange={(e) => { handleAssetsSelection({ value: e, assetgpUid: ag.uid, assetUid: ast.uid }) }}
                                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                                                                                        style={{ color: '#008081', }} />
                                                                                }

                                                                            />

                                                                        </FormGroup>
                                                                        </ListItemIcon>
                                                                        <ListItemText key={ast.id} primary={ast.id} />
                                                                    </ListItemButton>
                                                                </List>
                                                            ))
                                                            : <ListItem className={classes.noChamberList} >{chamberType ? "No " + chamberType + " Found" : 'No chambers Found'}</ListItem>
                                                    }





                                                </List>
                                            ))
                                            :
                                            " "
                                    }



                                </Grid>









                            </DialogContent>
                            <DialogActions >

                                <SecondaryButton text=" Cancel" onClick={handleDialogClose} color="primary" />

                                <PrimaryButton
                                    onClick={
                                        CompanyInfo &&
                                            CompanyInfo.settings.enableExcelDownload &&
                                            reportType === "excel"
                                            ? () => {
                                                handleExcelDownload(
                                                    selectedOption.value,
                                                    frequency,
                                                    dataType
                                                );
                                            }
                                            : () => {
                                                handlePDFDownload(
                                                    selectedOption.value,
                                                    frequency,
                                                    dataType,
                                                    isEvents
                                                );
                                            }
                                    }
                                    color="primary"
                                    text="Download"
                                />
                            </DialogActions>
                        </Dialog>
                    )
            }

        </>
    )
}

export default withStyles(styles)(GlobalDownload);







