/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import UserContext from 'Context/UserContext';
import API from 'API/apiConfig';
import cogoToast from 'cogo-toast';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  textField: {
    width: '100%',
  },
  saveButton: {
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  subheading:{
    fontFamily: 'Montserrat',
    fontSize:'14px'
  }
};

function Notifications({
classes,settingsState, settingsDispatchFn,
}) {
  const [SMSSettings, setSMSSettings] = useState(false);
  const [emailSettings, setEmailSettings] = useState(false);
  const [pushSettings, setPushSettings] = useState(false);

  const { SMS, EMAIL, PUSH } = settingsState;

  const userDetails = useContext(UserContext);
  const { UserDetails, updateCompanyNotification } = userDetails;

  // console.log('PUSH ', SMS, EMAIL, PUSH,UserDetails )
  const handleCheckboxs = (props) => {
    if (props === 'SMS') {
      // setSMSSettings(!SMSSettings);
      settingsDispatchFn({ type: "SMS", payload: !settingsState.SMS });
    }
    if (props === 'EMAIL') {
      settingsDispatchFn({ type: "EMAIL", payload: !settingsState.EMAIL });
      // setEmailSettings(!emailSettings);
    }
    if (props === 'PUSH') {
      settingsDispatchFn({ type: "PUSH", payload: !settingsState.PUSH });
      // setPushSettings(!pushSettings);
    }
  };

  useEffect(() => {
    if (UserDetails && UserDetails.notificationSettings) {
      // console.log('User details ',UserDetails && UserDetails.notificationSettings )
      settingsDispatchFn({ type: "SMS", payload: UserDetails.notificationSettings.SMS ? UserDetails.notificationSettings.SMS : false });
      settingsDispatchFn({ type: "EMAIL", payload: UserDetails.notificationSettings.email ? UserDetails.notificationSettings.email : false });
      settingsDispatchFn({ type: "PUSH", payload: UserDetails.notificationSettings.push ? UserDetails.notificationSettings.push: false });

      // setSMSSettings(UserDetails.notificationSettings && UserDetails.notificationSettings.SMSSettings ? UserDetails.notificationSettings.SMSSettings : false);
      // setEmailSettings(UserDetails.notificationSettings && UserDetails.notificationSettings.emailSettings ? UserDetails.notificationSettings.emailSettings : false);
      // setPushSettings(UserDetails.notificationSettings && UserDetails.notificationSettings.pushSettings ? UserDetails.notificationSettings.pushSettings : false);
    }
  }, [UserDetails]);

  const handleUpdateNotification = () => {
    const oldSMS = UserDetails.notificationSettings && UserDetails.notificationSettings.SMSSettings ? UserDetails.notificationSettings.SMSSettings : false;
    const oldPush = UserDetails.notificationSettings && UserDetails.notificationSettings.emailSettings ? UserDetails.notificationSettings.emailSettings : false;
    const oldEmail = UserDetails.notificationSettings && UserDetails.notificationSettings.pushSettings ? UserDetails.notificationSettings.pushSettings : false;

    if (SMSSettings !== oldSMS || pushSettings !== oldPush || emailSettings !== oldEmail) {
      const patchNotifications = [{
        op: 'replace',
        path: '/notificationSettings',
        value: { SMSSettings, emailSettings, pushSettings },
      }];
      // console.log('PAtch noti', patchNotifications[0].value)
      API
        .patch(`${APIURL}/users`, patchNotifications)
        .then((resp) => {
          if (resp.status === 200 && resp.data.status === 'success') {
            // We need to update the  Notification Setting of UserDetails
            updateCompanyNotification(patchNotifications[0].value);
            cogoToast.success('Notification settings is updated Successfully', { position: 'top-right' });
          } else {
            cogoToast.error('Error updating notification settings', { position: 'top-right' });
            console.error('error patching notification settings', resp);
          }
        })
        .catch((err) => {
          cogoToast.error('Error updating notification settings', { position: 'top-right' });
          console.error('error patching notification settings got caught', err);
        });
    }
  };

  return (
    <div>

      <Grid container md={12} xs={12} sm={12} item spacing={2} alignItems="center">
        <Grid item  className={classes.subheading} >
          <Typography  variant="p" component="span">
          Select one or more fields to get notified:
          </Typography>
         
        </Grid>
        
        <Grid item  className={classes.subheading}>
          <FormControlLabel
            control={(
              <Checkbox
                

                checked={SMS}
                onChange={() => { handleCheckboxs('SMS'); }}

                // checked={SMS}
                // onChange={() => { onCheckboxClick('SMS'); }}
                value="SMS"
                style={{ color: '#008081' }}
              />
                                )}
            label="SMS"
          />
        </Grid>
        <Grid item  className={classes.subheading}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={EMAIL}
                onChange={() => { handleCheckboxs('EMAIL'); }}

                // checked={EMAIL}
                // onChange={() => { onCheckboxClick('email'); }}
                value="email"
                style={{ color: '#008081' }}
              />
                                )}
            label="EMAIL"
          />
        </Grid>
        <Grid item  className={classes.subheading}>
          <FormControlLabel
            control={(
              <Checkbox
              // checked={PUSH}
              // onChange={() => { onCheckboxClick('push'); }}
              
                checked={PUSH}
                onChange={() => { handleCheckboxs('PUSH'); }}
                value="push"
                style={{ color: '#008081' }}
              />
                                )}
            label="PUSH"
          />
        </Grid>
      </Grid>


      
    </div>
  );
}

Notifications.propTypes = {
  //   classes: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.func,
};

Notifications.defaultProps = {
  onUpdate: (event) => { event.preventDefault(); },
};

export default withStyles(styles)(Notifications);
