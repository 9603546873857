import React, { useEffect, useContext } from "react";
import { withStyles } from '@mui/styles';
// import response from "./response";
import API from 'API/apiConfig';
import UserContext from 'Context/UserContext';
// import './table.css';
import '../Events Summary/tableStyle.css'

import { useState } from "react";
import { Grid, Tooltip, } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CircularProgress } from '@mui/material';
const APIURL = process.env.REACT_APP_APIURL;


const styles = {

    // table: {

    //     width: "100%",
    //     margin: "0",
    //     padding: "0",
    //     borderCollapse: "collapse",
    //     borderSpacing: "0",
    //     backgroundColor: '#fff',


    //     // border:'2 px solid #000'

    // },
    td: {
        // border: '1px solid #000',
        textAlign: 'left',
        padding: '0.8rem',
        // width: '2rem'
    },
    tableDetail: {
        paddingRight: '3rem',
        padding: '0.8rem',
        fontSize: '0.8em',
    },
    th: {
        // width:'100rem',
        paddingRight: '3rem',
        padding: '0.6rem',
        fontSize: '0.9em',
        backgroundColor: '#008081',
        color: 'white',

        position: "sticky",
        // top: "0",
        // overFlow:'none',
        // width: "100%",
        // left: "0",
        // top: "auto",
        // borderTopWidth: "1px",
        // /*only relevant for first row*/
        // marginTop: -"1px",
        /*compensate for top border*/
    },
    dateNo: {
        paddingRight: '3rem',
        padding: '0.8rem',
        marginTop: -"1px",

    },
    outerSpan: {
        height: '15px',

        border: '1px solid  #D3D3D3',
        // opacity: opacity,
        display: 'inline-block',
        marginRight: '3px',
        cursor: 'pointer',
        "&:hover": {
            boxShadow: "10px 10px 5px -3px rgba(108, 122, 137, 0.6)",
        },
    }

};


const TableContent = (props) => {
    const { classes, daysno, value, menuItem, handleMonthDatesT, searchvalue, } = props;
    // const [response, setResponse] = useState([]);
    const [TABLE_ROWS, setTableRows] = useState([0]);
    const [originaltableValue, setOriginaltableValue] = useState([])
    const [loading, setLoading] = useState(true);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
    const history = useHistory();

    const companyDetails = useContext(UserContext);
    const {  CompanyInfo } = companyDetails;
    


    const daysNoarray = []
    daysNoarray.push(daysno)

    //  console.log('Props in TC', props)

    //to create dymanic array based of days in month, then we will map over it to create the columns
    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }




    const getEventsFromServer = () => {
        
        const startInUnix = Math.round((value) / 1000);
        const endInUnix = Math.round(+value / 1000) + (daysno) * 24 * 60 * 60  //adding no of month days to start time 
      
        setLoading(true)
        handleMonthDatesT(startInUnix, endInUnix)
        // const companyString=accountUser && accountUser.companyId[0]
        // const companyName=companyString.endsWith("PIXEL") 
        // ? accountUser.companyId[0].replace('~PIXEL','') : accountUser
        const companyName = CompanyInfo && CompanyInfo.companyId;
       
        const timezone = moment.tz.guess()  //guess the timeZone
        API.get(`${APIURL}/events/summary?companyId=${companyName}&startTime=${startInUnix}&endTime=${endInUnix}&timezone=${timezone}&response=${'table'}`)
            .then((response) => {
                const res = response.data.data;
                // console.log('res', res)

                // setResponse(res)
                // const EVENTS = [];
                let TABLE_ROWS_temp = [];

                Object.keys(res).forEach(agId => {
                    const eventsInAg = res[agId];

                    Object.keys(eventsInAg).forEach((assetId,
                        //  assetIndex
                    ) => {
                        const devicesInAsset = Object.keys(eventsInAg[assetId]);

                        const splitContent = assetId.split(" | ");

                        if (!devicesInAsset.length) {
                            TABLE_ROWS_temp.push({
                                // agId: !assetIndex ? agId : '',
                                agId: agId,
                                assetId: splitContent && splitContent[0],
                                deviceId: "–",
                                assetUid: splitContent && splitContent[1],
                                // events: Object.keys(eventsInDevice).map(eDate => ({ date: eDate, rating: eventsInDevice[eDate] })),
                                events: range(1, daysno).map(() => {

                                    return ({
                                        rating: undefined
                                    })
                                }),
                            });
                            return
                        }
                        devicesInAsset.forEach((d,
                            //  deviceIndex
                        ) => {
                            let eventsInDevice = eventsInAg[assetId][d];
                            const splitContent = assetId.split(" | ");
                            // console.log('eventsInDevice',eventsInDevice)
                            TABLE_ROWS_temp.push({
                                // agId: (assetIndex || (!assetIndex && deviceIndex))? '' : agId,
                                // assetId: !deviceIndex ? assetId : '',
                                agId: agId,
                                assetId: splitContent && splitContent[0],
                                deviceId: d,
                                tolerancesetting: splitContent && splitContent[2],
                                assetUid: splitContent && splitContent[1],
                                // events: Object.keys(eventsInDevice).map(eDate => ({ date: eDate, rating: eventsInDevice[eDate] })),
                                events: Object.keys(eventsInDevice).map((eDate) => {
                                    let rating = 0;
                                        const dateObject=Object.keys(eventsInDevice[eDate])
                                       
                                        if(dateObject.length){ 
                                        if (menuItem === "All Violations") {
                                            if (eventsInDevice[eDate].T && eventsInDevice[eDate].H) {
                                                if (eventsInDevice[eDate].T > eventsInDevice[eDate].H) {
                                                    rating = eventsInDevice[eDate].T            //if both are present , compare and pick T value
                                                } else {
                                                    rating = eventsInDevice[eDate].H
                                                }
                                            } else {
                                                if (eventsInDevice[eDate].T) {                  //if only T is present , pick T value
                                                    rating = eventsInDevice[eDate].T
                                                }
                                                if (eventsInDevice[eDate].H) {
                                                    rating = eventsInDevice[eDate].H           //if only H is present , pick H value
                                                }
    
                                            }
    
                                        }
                                        if (menuItem === "Temperature Violations") {
                                            rating = eventsInDevice[eDate].T
                                        }
                                        if (menuItem === "Humidity Violations") {
                                            rating = eventsInDevice[eDate].H
                                        }
                                        }
                                        else{
                                            //we get {} if there is no ping., so put - in that place
                                            rating= undefined
                                        }
    
                                        return ({
                                            date: eDate, rating: rating
                                        })
                                  
                                   
                                }) 

                            });

                            //  if(menuItem === "Only Alerted Warehouse"){
                            //     console.log('TABLE_ROWS_temp***', TABLE_ROWS_temp, originaltableValue )
                            //     const Filter_Table_rows=TABLE_ROWS_temp.filter((e)=>e.events.filter((m)=>m.rating).length);
                            //     console.log('Filter_Table_rows', Filter_Table_rows)
                            //     TABLE_ROWS_temp= Filter_Table_rows;
                            // }





                            // if (TABLE_ROWS_temp.events.filter(e => e.rating).length) {
                            //     TABLE_ROWS_temp.push(TABLE_ROWS_temp);
                            // }


                        })
                    });
                });

                setTableRows(TABLE_ROWS_temp);
                setOriginaltableValue(TABLE_ROWS_temp)
                setLoading(false);
                // if(menuItem === "Only Alerted Warehouse"){
                //     console.log('HAHAHAH',TABLE_ROWS_temp, TABLE_ROWS_temp.filter((novio)=>novio.agId));
                //     const filterWarehouse= TABLE_ROWS_temp.filter((novio)=>novio.agId)
                //     console.log('filterWarehouse', filterWarehouse)
                // }




            })
            .catch((err) => {
                console.log('Err is ', err)
            })
    }




    useEffect(() => {
        getEventsFromServer();
    }, [value, menuItem, CompanyInfo])



    useEffect(() => {
        if (searchvalue && searchvalue.length > 1) {
            const result = originaltableValue && originaltableValue.filter((d) => (searchvalue
                ? (d.agId || ' ').toLowerCase().includes(searchvalue.toLowerCase()) ||
                (d.assetId || ' ').toLowerCase().includes(searchvalue.toLowerCase())
                : true
            ));



            setTableRows(result)
        }

        if (!searchvalue) {
            //   console.log('SV', originaltableValue)
            setTableRows(originaltableValue)
        }
    }, [searchvalue])


   



    // useEffect(()=>{
    //     if( menuItem === "Only Alerted Warehouse"){
    //         const FW= originaltableValue.filter((e)=>e.events.filter((m)=>m.rating).length);
    //        
    //         setOriginaltableValue(FW)
    //     }
    // },[menuItem])




    // const filterWarehouse= originaltableValue.map((novio)=>novio.events.map((m)=>m.rating !== 0))


    // const handleOpacity=(r)=>{

    // let value;
    // r.events.map((e)=>{
    //     const rating = e.rating ;
    //     const opacity = (rating / 4) || 0  ;
    //     value= opacity;

    // })
    // console.log('Value', value)
    // return value;
    // }

    const handleviewDetail = (uid, st) => {

        const assetuid = uid;
        const startTime = st;
        const endTime = moment(new Date(startTime * 1000)).add(1, 'day').unix()

        history.push({ pathname: '/dashboard', search: `?assetUid=${assetuid}&&startTime=${startTime}&&endTime=${endTime}&&RS=ReportSection&&CV=TableView`, state: { isActive: true }, })
    }


    const handletime = (value) => {
        if (value) {
            
            if (value.includes(("SI"))) {
                return "instant";
            } else {
                const sec = parseInt(value, 10); // convert value to number if it's string
                let hours = Math.floor(sec / 3600); // get hours
                let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
                let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
                // add 0 if value < 10; Example: 2 => 02
                if (hours < 10) { hours = "0" + hours; }
                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                // return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
                return hours + " hour " + minutes + " min "
            }

        } else {
            return "–";
        }


    }

    const handlePossibleVio = (tt) => {
        const tolerance = tt;
        const possiblevio = 86400 / tolerance;
        return possiblevio;
    }

    const handleColor = (id, rating, tt) => {

        const tolerance = tt;
        // const ratingVal=rating;
        const possiblevio = 86400 / tolerance;
        const range = possiblevio / 3;   //to find the 1 parts in a day of tt

        const range2 = range * 2;

        if (rating > 0 && rating <= range) {
            return "#EFD1D1"
        }
        if (rating > range && rating <= range2) {
            return "#F08D8D"
        }
        if (rating > range2) {
            return "#E43737"
        }
        return "#fff";

    }


// console.log('TABLE_ROWS',TABLE_ROWS)
    // let ROWS = [{ warehouseId: '', chamberId: '', deviceId: "", events: [{ date: '', rating: '' }] }]
    // let newRows= [{ warehouseId: '', chamberId: [ch1, ch2,ch3,ch4], deviceId: [d1,d2,d3,d4], events: [{ date: '', rating: '' }] }, { date: '', rating: '' }] }, { date: '', rating: '' }] },{ date: '', rating: '' }] }]
    return (
        <>

            {
                loading ? (
                    <>

                        <div
                            style={{
                                position: "fixed",
                                display: "block",
                                width: "100%",
                                height: "100%",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0,0,0,0.5)",
                                zIndex: "2",
                            }}
                        >
                            <CircularProgress
                                style={{ position: "absolute", top: "50%", left: "50%" }}
                            />
                        </div>

                    </>
                ) :
                    (
                        <>
                            <div
                                style={{
                                    overflowX: 'auto',
                                    overflowY: 'auto',
                                    height: '70vh',
                                    backgroundColor: '#fff',
                                    border: "2px solid #D3D3D3",
                                    width: '100%'
                                }}>
                                <table border="2" bordercolor="#D3D3D3" style={{ width: '100%' }} >
                                    <tr className={classes.td}>
                                        <th className={classes.th}>
                                            {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ?
                                                CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType :
                                                "Warehouse "} ID</th>
                                        <th className={classes.th}>
                                            {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ?
                                                CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType :
                                                "Chamber "}  ID</th>
                                        <th className={classes.th}>Device ID</th>
                                        <th className={classes.th}>Tolerance Setting</th>
                                        {range(1, daysno).map((a) => {
                                            return (
                                                <>
                                                    <th className={classes.th}>{a} {months[value && value.getMonth()]}
                                                    </th>
                                                </>
                                            )
                                        })}
                                    </tr>

                                    {TABLE_ROWS &&  TABLE_ROWS.length  > 0 && (TABLE_ROWS.map(r => {

                                        return (
                                            <>
                                                {/* {
                                loading ?
                                (<>
                                
                                <div className={classes.overlay}>
        <CircularProgress
          style={{ position: 'absolute', top: '70%', left: '50%' }}
        />
      </div>
                                </>) :  */}
                                                {/* ( */}
                                                <>
                                                    <tbody style={{ width: '100%', overFlow: 'auto', }}>
                                                        <tr key={`${r.agId}-${r.assetId}-${r.deviceId}`}>
                                                            <td className={classes.tableDetail} >{r.agId}</td>
                                                            <td className={classes.tableDetail}>{r.assetId}</td>
                                                            <td className={classes.tableDetail}>{r.deviceId}</td>
                                                            <td className={classes.tableDetail}>{Number(parseInt(r.tolerancesetting)) ? handletime(r.tolerancesetting) : "–"}</td>
                                                           

                                                            {(r.events || []).map((e) => {

                                                                return (

                                                                    <td key={e.date}>
                                                                        {(Number(e.rating)) || e.rating === 0 ?
                                                                            <Tooltip
                                                                                placement="bottom"
                                                                                PopperProps={{
                                                                                    sx: {
                                                                                        "& .MuiTooltip-tooltip": {

                                                                                            color: "#000",
                                                                                            border: '1px solid #cecece',
                                                                                            backgroundColor: '#fff',
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                key={r.assetId}
                                                                                title={<>
                                                                                    <Grid >
                                                                                        <Grid style={{ fontSize: '1.3em', }}>
                                                                                            <span style={{fontWeight:600, color:'rgba(0,0,0,0.8)'}}>Maximum possible violations in a day: </span>
                                                                                            <span style={{color:'rgba(0,0,0,0.8)'}}>{Number(parseInt(r.tolerancesetting)) ? handlePossibleVio(parseInt(r.tolerancesetting)) : "N/A"} times </span>
                                                                                             </Grid>
                                                                                        <Grid style={{ fontSize: '1.3em', }}>
                                                                                        <span style={{fontWeight:600, color:'rgba(0,0,0,0.8)'}}>Number of Violations occured: </span>
                                                                                        <span style={{color:'rgba(0,0,0,0.8)'}}> {Number(parseInt(r.tolerancesetting)) ? e.rating : "N/A"}</span>
                                                                                             </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                                }

                                                                            >
                                                                                <span
                                                                                    className={classes.outerSpan}
                                                                                    style={{
                                                                                        width: '30px',

                                                                                    }} >
                                                                                    <span

                                                                                        onClick={() => handleviewDetail(r.assetUid, e.date)}
                                                                                        style={{
                                                                                            width: '30px',
                                                                                            height: '15px',
                                                                                            backgroundColor: handleColor(r.assetId, e.rating, parseInt(r.tolerancesetting)),
                                                                                            //    border: '1px solid #000',

                                                                                            // opacity: opacity,
                                                                                            display: 'inline-block',
                                                                                            marginRight: '3px',
                                                                                            cursor: 'pointer'
                                                                                        }} > </span></span>


                                                                            </Tooltip> :
                                                                            "–"
                                                                        }

                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>




                                                    </tbody>
                                                </>


                                            </>
                                        )
                                    }))
                                   
                                }





                                </table>

                            </div>


                        </>
                    )
            }


        </>

    )

}
export default withStyles(styles)(TableContent)


